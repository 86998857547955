import {
  Box,
  Container,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import React from "react";
import styles from "./AccountSettings.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../../assets/images/back.svg";
import { Language, PersonalDetail, Security, Support } from "../components";
import { Sidebar } from "../../../components/layout";

const AccountSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultTab = "personalDetails";
  const [value, setValue] = React.useState(defaultTab);
  const [tabHistory, setTabHistory] = React.useState([defaultTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabHistory((prevHistory) => [...prevHistory, newValue]);
  };

  const handleBack = () => {
    if (tabHistory.length > 1) {
      const newHistory = [...tabHistory];
      newHistory.pop();
      const previousTab = newHistory[newHistory.length - 1];
      setTabHistory(newHistory);
      setValue(previousTab);
    } else {
      navigate(-1);
    }
  };
  return (
       <>
       <Sidebar currentPath={location.pathname} />
    <Container sx={{ flexGrow: 1 }}>
      <Box className={styles.accountContainer}>
        <Box className={styles.topBar}>
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="account settings tabs"
            className={styles.tabs}
          >
            <Tab
              className={
                value === "personalDetails" ? styles.activeTab : styles.tab
              }
              value="personalDetails"
              label="Personal Details"
            />
            <Tab
              className={value === "security" ? styles.activeTab : styles.tab}
              value="security"
              label="Security"
            />
            <Tab
              className={value === "support" ? styles.activeTab : styles.tab}
              value="support"
              label="Support"
            />
          </Tabs>
        </Box>
        <Box className={styles.tabContent}>
          {value === "personalDetails" && <PersonalDetail />}
          {value === "security" && <Security />}
          {value === "language" && <Language />}
          {value === "support" && <Support />}
        </Box>
      </Box>
    </Container></>
  );
};

export default AccountSettings;
