import React, { useEffect, useState, useRef, useCallback } from "react";
import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import styles from "./PersonalDetail.module.css";
import images from "../../../../assets/image";
import { EditableInput } from "../../../../components/common/Input";
import {
  fetchProfileApi,
  updateProfileApi,
} from "../../../../data/api/profileApi";
import {
  fetchProfile,
  updateProfile,
} from "../../../../data/redux/actions/profileActions";
import useNotification from "../../../../hooks/notification";


const PersonalDetail = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.userData.token);
  const profile = useSelector((state) => state.profile.profileData);
  const { showNotification, Notification } = useNotification();

  const [profileDetails, setProfileDetails] = useState({
    name: profile?.name || "",
    address: profile?.address || "",
    mobile: profile?.mobile || "",
    profileImage: profile?.profile_picture || "",
  });

  const fileInputRef = useRef(null);

  const handleProfileChange = (field, value) => {
    setProfileDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  const handleFileChange = useCallback((event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        handleProfileChange("profileImage", reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const fetchProfileHandler = useCallback(async () => {
    if (!profile) {
      try {
        dispatch(fetchProfile.request());
        const response = await fetchProfileApi(token);
        dispatch(fetchProfile.success(response.data));
      } catch (error) {
        console.error("Personal detail page error:", error);
        showNotification(error.data.error,'error')
        dispatch(fetchProfile.failure(error?.data?.error));
      }
    }
  }, [dispatch, profile, token]);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append("name", profileDetails.name);
    formData.append("address", profileDetails.address);
    formData.append("contactNumber", profileDetails.mobile);
    if (fileInputRef.current.files[0]) {
      formData.append("profile_picture", fileInputRef.current.files[0]);
    }
    try {
      dispatch(updateProfile.request());
      const response = await updateProfileApi(formData, token);
      showNotification(response.message,'success')
      dispatch(updateProfile.success(response.data));
    } catch (error) {
      console.error("Personal detail update error:", error);
      showNotification(error.data.error,'error')
      dispatch(updateProfile.failure(error?.data?.error));
    }
  };

  useEffect(() => {
    fetchProfileHandler();
  }, [fetchProfileHandler]);

  return (
    <Box className={styles.personalDetailContainer}>
      <Notification />
      <Typography variant="h6" component="h2">
        Profile
      </Typography>
      <Typography variant="body1">
        Update your photo and personal details here.
      </Typography>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "2px solid #d9d9d98a",
          margin: "10px 0",
        }}
      />
      <Box className={styles.imageContainer}>
        <Box className={styles.imageLeft}>
          <Typography variant="h6" component="h2">
            Your photo
          </Typography>
          <Typography variant="body1">
            This will be displayed on your profile.
          </Typography>
        </Box>
        <Box className={styles.imageRight}>
          <Box className={styles.profileImage} onClick={handleAvatarClick}>
            <Avatar sx={{width: 70, height: 70}} src={profileDetails.profileImage || images.default} />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>
          <Box className={styles.button}>
            <Button sx={{ color: "#000" }} variant="text">
              Delete
            </Button>
            <Button variant="text" onClick={handleUpdateProfile}>
              Update
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "1px solid #d9d9d98a",
        }}
      />
      <Box className={styles.inputBox}>
        <Typography variant="h6" component="h5">
          Name
        </Typography>
        <EditableInput
          initialValue={profileDetails.name}
          onChange={(val) => handleProfileChange("name", val)}
          label="Name"
          variant="filled"
        />
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "1px solid #d9d9d98a",
        }}
      />
      <Box className={styles.inputBox}>
        <Typography variant="h6" component="h5">
          Mobile Number
        </Typography>
        <EditableInput
          initialValue={profileDetails.mobile}
          onChange={(val) => handleProfileChange("mobile", val)}
          type="number"
          label="Mobile Number"
          variant="filled"
          placeholder="Enter Mobile Number"
        />
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "1px solid #d9d9d98a",
        }}
      />
      <Box className={styles.inputBox}>
        <Typography variant="h6" component="h5">
          Address
        </Typography>
        <EditableInput
          initialValue={profileDetails.address}
          onChange={(val) => handleProfileChange("address", val)}
          placeholder="Address"
          label="Address"
          variant="filled"
          multiline
          rows={5}
        />
      </Box>
    </Box>
  );
};

export default PersonalDetail;
