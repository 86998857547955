import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import styles from "./Language.module.css";
import { LanguageSelect } from "../../../../components/common/Input";

const Language = () => {
  return (
    <Box>
      <Typography variant="h6" component="h2">
        Profile
      </Typography>
      <Typography variant="p" component="p">
        Update Language here.
      </Typography>
      <Divider sx={{ width: '100%', borderBottom: "2px solid #d9d9d98a", margin: "10px 0" }} />
      <Box className={styles.inputBox}>
        <Typography variant="h6" component="h5">
          Language
        </Typography>
        <LanguageSelect className={styles.languageDropdown} />
      </Box>
    </Box>
  );
};

export default Language;
