import React from 'react';
import { Button, Box } from '@mui/material';

const GenerateButton = ({title,handelButtonClick,styles,StartIcon,EndIcon}) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'start', my: 1 }}>
      <Button
        variant="contained"
        sx={{
          background: 'linear-gradient(180deg, #86A1FF 0%, #3864FE 100%)',
          color: 'primary.contrastText',
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 3,
          borderRadius: '9999px',
          boxShadow: 1,
          textTransform: "none",
          transform: 'scale(1)',
          transition: 'transform 0.2s',
          '&:hover': {
            background: 'linear-gradient(180deg, #86A1FF 0%, #3864FE 100%)', 
            transform: 'scale(1.025)',
          },
          '&:focus': {
            outline: 'none',
          },
          fontSize: '0.8rem',
          fontWeight: 600, 
          ...styles
        }}
        endIcon = {EndIcon}
        startIcon = {StartIcon}
        onClick={()=>handelButtonClick()}
      >
        {title}
      </Button>
    </Box>
  );
};
GenerateButton.defaultProps = {
  title:'',
  handelButtonClick:()=>{},
  styles:{},
  StartIcon:()=>{},
  EndIcon:()=>{}
}
export default GenerateButton;
