import React from 'react';
import styles from './PasswordRequirements.module.css';
import Images from '../../../../../assets/image';

const PasswordRequirements = ({ requirements }) => {
  return (
    <div className={styles.requirementsContainer}>
      {requirements.map((req, index) => (
        <div key={index} className={styles.requirement}>
          <span className={styles.checkmark}>
            {req.met ? <img src={Images.check} alt="Check" /> : '❌'}
          </span>
          {req.text}
        </div>
      ))}
    </div>
  );
};

export default PasswordRequirements;
