import { apiRequest } from "./utils.js";

export const fetchProfileApi = async (token) => {
  return apiRequest("GET", "/user/account/details", null, token);
};
export const updateProfileApi = async (data, token) => {
  return apiRequest("PUT", "/user/account/details", data, token);
};
export const chnagePasswordApi = async (data, token) => {
  return apiRequest("POST", "/user/auth/change-password", data, token);
};
