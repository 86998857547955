import React from "react";
import styles from "./Footer.module.css";
import Images from "../../../assets/image";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.topSection}>
        <div className={styles.links}>
          <ul>
            <li>Homeowner Services</li>
            <li>Find local businesses</li>
            <li>Services near me</li>
          </ul>
          <ul>
            <li>For service pros</li>
            <li>Register your business</li>
            <li>Business center</li>
            <li>Support</li>
          </ul>
          <ul>
            <li>Resources</li>
            <li>Solution center</li>
            <li>Project cost center</li>
            <li>FAQs</li>
            <li>Home Service membership</li>
          </ul>
          <ul>
            <li>About us</li>
            <li>How it works</li>
            <li>Who we are</li>
            <li>Careers</li>
            <li>Contact us</li>
          </ul>
        </div>
        <div className={styles.signup}>
          <h1>Sign up for free project cost information</h1>
          <input type="text" name="email" placeholder="Email" />
          <input type="text" name="zipcode" placeholder="Zipcode" />
          <button>Sign Up</button>
          <p>Download App From</p>
          <div className={styles.downloadLinks}>
            <img src={Images.google} alt="Google Play Store" />
            <img src={Images.apple} alt="Apple App Store" />
          </div>
        </div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.branding}>
          <div className={styles.logo}>Home Service</div>
          <ul className={styles.legalLinks}>
            <li>Terms</li>
            <li>Privacy</li>
            <li>Cookies</li>
          </ul>
        </div>
        <div className={styles.socialMedia}>
          <div className={styles.icon}>
            <img src={Images.linkedin} alt="LinkedIn" />
          </div>
          <div className={styles.icon}>
            <img src={Images.facebook} alt="Facebook" />
          </div>
          <div className={styles.icon}>
            <img src={Images.twitter} alt="Twitter" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
