import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../data/redux/actions/authActions";
import Loading from "../components/common/Loading";

const PrivateRoute = ({ element }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.userData);
  const checkLoginStatus = useCallback(async () => {
    try {
      const userData = localStorage.getItem("user");
      if (userData) {
        const parsedUserData = JSON.parse(userData);
        if (parsedUserData) {
          dispatch(login.success(parsedUserData));
        }
      }
    } catch (e) {
      console.error("Failed to load user data", e);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
  setTimeout(() => {
    checkLoginStatus();
  }, 2000);
  }, [checkLoginStatus]);

  if (loading) {
    return <Loading />;
  }

  return auth?.token ? element : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
