import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip
} from "@mui/material";
import styles from "./styles"; // Assuming styles are in the same file

const MCQSection = ({ mcqs, currentPage, selectedOptions, setSelectedOptions}) => {
  const mcqsPerPage = 3;
  
  const handleOptionChange = (mcqId, selectedOption) => {
    setSelectedOptions((prevSelectedOptions) => {
      // Check if the option for the current mcqId already exists
      const existingOptionIndex = prevSelectedOptions.findIndex(
        (option) => option.mcq_id === mcqId
      );

      if (existingOptionIndex >= 0) {
        const updatedOptions = [...prevSelectedOptions];
        updatedOptions[existingOptionIndex].selected_option = selectedOption;
        return updatedOptions;
      } else {
        // Add a new option
        return [
          ...prevSelectedOptions,
          { mcq_id: mcqId, selected_option: selectedOption },
        ];
      }
    });
  };

  return (
    <Box sx={styles.cardBody}>
      {mcqs.slice((currentPage-1) * mcqsPerPage, (currentPage) * mcqsPerPage).map((mcq, indx) => (
        <FormControl component="fieldset" key={mcq.id}>
          <FormLabel component="legend" sx={styles.text}>
            {(indx + 1) + 3 * (currentPage - 1)}. {mcq.question}
          </FormLabel>
          <RadioGroup
            name={`mcq-${mcq.id}`}
            value={
              selectedOptions.find((option) => option.mcq_id === mcq.id)
                ?.selected_option || ""
            }
            sx={{ marginLeft: "2rem" }}
            onChange={(e) => handleOptionChange(mcq.id, e.target.value)}
            row
          >
            {[mcq.option1, mcq.option2, mcq.option3, mcq.option4].map((option, idx) => (
              <Tooltip title={option} key={idx}>
                <FormControlLabel
                  value={option}
                  control={<Radio />}
                  label={option}
                  sx={{
                    ...styles.text,
                    width: "48%",
                    fontSize: "0.7rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              </Tooltip>
            ))}
          </RadioGroup>
        </FormControl>
      ))}
    </Box>
  );
};

export default MCQSection;
