import React from "react";
import styles from "./VerifyEmail.module.css";
import images from "../../../../../assets/image";
import { Button } from "../../../../../components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { resendVerificationEmailApi } from "../../../../../data/api/authApi";
import { resendVerificationEmail } from "../../../../../data/redux/actions/authActions";
import { useDispatch } from "react-redux";
import useNotification from "../../../../../hooks/notification";
import { maskEmail } from "../../../../../utils/helpers";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const maskedEmail = maskEmail(email);
const dispatch =  useDispatch();
const { showNotification, Notification } = useNotification();

  const resendEmailHandler =  async() => {
    try {
      dispatch(resendVerificationEmail.request());
      const response = await resendVerificationEmailApi(email);
      showNotification(response.message, 'success');
      dispatch(resendVerificationEmail.success(response));
    } catch (error) {
      console.error('Resend verification email error:', error?.data?.error || error.message);
      showNotification(error.data?.error || error.message, 'error');
      dispatch(resendVerificationEmail.failure(error?.response?.data?.error));
    }
  }

  return (
    <div className={styles.verificationContainer}>
      <Notification />
      <div className={styles.content}>
        <img src={images.emailVerify} alt="lock" />
        <h1>Verify your email</h1>
        <h1>Almost there! We’ve sent a verification email to {maskedEmail}</h1>
        <h1>
          You need to verify your email address to log into House services{" "}
        </h1>
        <Button
          onClick={resendEmailHandler}
          label="Resend email"
          fontSize="1rem"
          paddingVertical="1.8rem"
          paddingHorizontal=".8rem"
          margin="1rem 0"
        />
        <p onClick={()=>navigate('/sign-in')}>Back to login</p>
      </div>
    </div>
  );
};

export default VerifyEmail;
