import React, { useState } from 'react';
import styles from './TermsCheckbox.module.css';

const TermsCheckbox = () => {
  const [isCheckedOffers, setIsCheckedOffers] = useState(false);
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);

  const handleOffersCheckboxChange = (event) => {
    setIsCheckedOffers(event.target.checked);
  };

  const handleTermsCheckboxChange = (event) => {
    setIsCheckedTerms(event.target.checked);
  };

  return (
    <div className={styles.checkboxContainer}>
      <label>
        <input
          type="checkbox"
          checked={isCheckedOffers}
          onChange={handleOffersCheckboxChange}
        />
        I'd like to receive special email offers and discounts.
      </label>
      <label>
        <input
          type="checkbox"
          checked={isCheckedTerms}
          onChange={handleTermsCheckboxChange}
        />
        I agree to the terms of the membership agreements.
      </label>
    </div>
  );
};

export default TermsCheckbox;
