import React from 'react';
import { Button } from '../../../../components/common';
import styles from './ActionBox.module.css';

const ActionBox = ({ heading, buttonLabel, image }) => {
  return (
    <div className={styles.box}>
      <h1>{heading}</h1>
      <div className={styles.actionContainer}>
        <Button label={buttonLabel} fontSize={'11.5px'} paddingHorizontal={'12px'} paddingVertical={'25px'} />
        <img src={image} alt="frame" className={styles.image} />
      </div>
    </div>
  );
};

export default ActionBox;
