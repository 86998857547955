import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import styles from './EditableInput.module.css'
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";

const EditableInput = ({ initialValue, placeHolder, type="Text", isIcon=true, onChange, rows, cols, multiline }) => {
  const [value, setValue] = useState(initialValue);
  const [isEditable, setIsEditable] = useState(false);

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <TextField
    className={styles.editInputTextField}
      variant="outlined"
      label={value? '' : placeHolder}
      fullWidth
      value={value}
      onChange={handleChange}
      InputLabelProps={{
        shrink: false,
      }}
      InputProps={{
        readOnly: !isEditable,
        type: type,
        endAdornment: (
          <InputAdornment position="end">
            {isIcon && <IconButton edge="end" onClick={handleEditClick}>
              <EditIcon />
            </IconButton> }
          </InputAdornment>
        ),
      }}
      rows={rows}
      cols={cols}
      multiline={multiline}
    />
  );
};

export default EditableInput;
