import React, { useState } from "react";
import styles from "./InputCard.module.css";
import images from "../../../assets/image";

const InputCard = ({ placeholder, inputStyle, value, label, style, onChange, type = "text", name, error }) => {
  const [inputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  return (
    <div style={style} className={styles.inputContainer}>
      <label htmlFor={label}>{label}</label>
      <input
        id={label}
        style={inputStyle}
        value={value}
        name={name}
        type={inputType}
        onChange={onChange}
        placeholder={placeholder}
      />
      {type === "password" && (
        <img
          src={inputType === "password" ? images.invisible : images.visible}
          alt={inputType === "password" ? "Show Password" : "Hide Password"}
          className={styles.passwordIcon}
          onClick={togglePasswordVisibility}
        />
      )}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default InputCard;
