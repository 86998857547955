import React, { useRef, useState } from 'react';
import styles from './OtpInput.module.css';

const OtpInput = ({ numInputs, onChange, onComplete }) => {
  const [otp, setOtp] = useState(Array(numInputs).fill(''));
  const inputRefs = useRef([]);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      onChange(newOtp.join(''));

      if (index < otp.length - 1 && value !== '') {
        inputRefs.current[index + 1].focus();
      } else if (index === otp.length - 1 && value !== '') {
        inputRefs.current[index].blur();
        onComplete(newOtp.join(''));
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain');
    if (/^\d{1,4}$/.test(pasteData)) {
      const newOtp = pasteData.split('').slice(0, otp.length);
      setOtp(newOtp);
      newOtp.forEach((value, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = value;
        }
      });
      onChange(newOtp.join(''));
      if (newOtp.length === otp.length) {
        onComplete(newOtp.join(''));
      }
    }
  };

  return (
    <div className={styles.otpContainer}>
      {otp.map((digit, index) => (
        <input
          className={styles.input}
          key={index}
          type="text"
          maxLength="1"
          pattern="[0-9]*"
          inputMode="numeric"
          ref={(el) => (inputRefs.current[index] = el)}
          value={digit}
          onChange={(e) => handleInputChange(index, e)}
          onPaste={(e) => handlePaste(e)}
        />
      ))}
    </div>
  );
};


export default OtpInput;
