module.exports = {
    maskEmail: (email) => {
        const [localPart, domainPart] = email.split('@');

        const visibleChars = 3;

        const maskedLocalPart = localPart.slice(0, visibleChars) + '*'.repeat(localPart.length - visibleChars);

        return `${maskedLocalPart}@${domainPart}`;
    },

}