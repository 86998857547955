import React from "react";
import styles from "./Header.module.css";
import { Button } from "../../common";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  
  return (
    <header className={styles.header}>
      <div className={styles.logo}>Home Service</div>
      <nav className={styles.nav}>
        <Button
          label="Sign In / Sign Up"
          backgroundColor="transparent"
          color="black"
          border="none"
          fontSize="14px"
          onClick={() => navigate("/sign-up")}
        />
        <Button
          label="Request a Quote"
          fontSize="14px"
          paddingHorizontal="9px"
          paddingVertical="17px"
         
        />
        <Button
          label="Join Our Pro Network"
          fontSize="14px"
          paddingHorizontal="9px"
          paddingVertical="17px"
          backgroundColor="transparent"
          color="black"
        />
      </nav>
    </header>
  );
};

export default Header;
