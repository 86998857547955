import React, { useState, useCallback } from "react";
import styles from "./SetNewPassword.module.css";
import images from "../../../../../assets/image";
import { Button, InputCard } from "../../../../../components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useNotification from "../../../../../hooks/notification";
import { setNewPassword } from "../../../../../data/redux/actions/authActions";
import { setNewPassordApi } from "../../../../../data/api/authApi";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showNotification, Notification } = useNotification();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const islogin = queryParams.get("islogin") === 'true';
  const otpNumber = queryParams.get("otpNumber");

  const [form, setForm] = useState({
    newpassword: "",
    confirmNewPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    newpassword: "",
    confirmNewPassword: "",
  });

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!form.newpassword) {
      errors.newpassword = "New Password is required.";
    }

    if (!form.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm New Password is required.";
    } else if (form.newpassword !== form.confirmNewPassword) {
      errors.confirmNewPassword = "Passwords do not match.";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }
    try {
      dispatch(setNewPassword.request());
      const response = await setNewPassordApi({
        email,
        otpNumber,
        newPassword: form.newpassword,
        confirmNewPassword: form.confirmNewPassword,
      });
      showNotification(response.message, "success");
      dispatch(setNewPassword.success(response));
     if(islogin){
      navigate('/account-settings')
     }else{
      navigate("/sign-in");
     }
    } catch (error) {
      showNotification(error.data?.error || "An error occurred", "error");
      dispatch(
        setNewPassword.failure(error.data?.error || "An error occurred")
      );
    }
  };

  return (
    <div className={styles.container}>
      <Notification />
      <div className={styles.main}>
        <img src={images.password} alt="alt" />
        <InputCard
          style={{ margin: "2rem 0" }}
          placeholder="New Password"
          type="password"
          name="newpassword"
          value={form.newpassword}
          onChange={handleChange}
          error={formErrors.newpassword}
        />
        <InputCard
          style={{ margin: "1.5rem 0 3rem 0" }}
          inputStyle={{ fontSize: "1.3rem" }}
          placeholder="Confirm New Password"
          type="password"
          name="confirmNewPassword"
          value={form.confirmNewPassword}
          onChange={handleChange}
          error={formErrors.confirmNewPassword}
        />
        <Button
          onClick={handleSubmit}
          label="Change Password"
          fontSize="1.1rem"
          paddingHorizontal="1rem"
          width="100%"
        />
      </div>
    </div>
  );
};

export default SetNewPassword;
