import React, { useState, useCallback } from "react";
import styles from "./ResetPassword.module.css";
import images from "../../../../../assets/image";
import { Button, InputCard } from "../../../../../components/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useNotification from "../../../../../hooks/notification";
import { Box } from "@mui/material";
import { chnagePassword } from "../../../../../data/redux/actions/profileActions";
import { chnagePasswordApi } from "../../../../../data/api/profileApi";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showNotification, Notification } = useNotification();
  const token = useSelector((state) => state.auth.userData.token);
  const profile = useSelector((state) => state.profile.profileData);

  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!form.newPassword) {
      errors.newpassword = "New Password is required.";
    }
    if (!form.oldPassword) {
        errors.oldPassword = "Old  Password is required.";
      }

    if (!form.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm New Password is required.";
    } else if (form.newPassword !== form.confirmNewPassword) {
      errors.confirmNewPassword = "Passwords do not match.";
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }

    try {
        dispatch(chnagePassword.request());
        const response = await chnagePasswordApi(form,token);
        showNotification(response.message, "success");
        dispatch(chnagePassword.success(response));
    } catch (error) {
        console.log('change password error ', error)
        showNotification(error.data.error, "error")
        dispatch(chnagePassword.failure(error.data.error));
    }
  };

  return (
    <div className={styles.container}>
      <Notification />
      <div className={styles.main}> 
        <Box sx={{ position: 'absolute',left: '10%', top: '5%'}}>
            <img style={{width: '70%', cursor: 'pointer'}} onClick={()=>navigate(-1)} src={images.backArrow} alt="back arrow" />
        </Box>
        <img src={images.password} alt="alt" />
        <InputCard
          style={{ margin: ".8rem 0" }}
          placeholder="Old Password"
          type="password"
          name="oldPassword"
          value={form.oldPassword}
          onChange={handleChange}
          error={formErrors.oldPassword}
        />
        <InputCard
          style={{ margin: ".8rem 0" }}
          placeholder="New Password"
          type="password"
          name="newPassword"
          value={form.newPassword}
          onChange={handleChange}
          error={formErrors.newPassword}
        />
        <InputCard
          style={{ margin: ".5rem 0 3rem 0" }}
          inputStyle={{ fontSize: "1.3rem" }}
          placeholder="Confirm New Password"
          type="password"
          name="confirmNewPassword"
          value={form.confirmNewPassword}
          onChange={handleChange}
          error={formErrors.confirmNewPassword}
        />
        <Button
          onClick={handleSubmit}
          label="Change Password"
          fontSize="1.1rem"
          paddingHorizontal="1rem"
          width="100%"
        />
      </div>
    </div>
  );
};

export default ResetPassword;
