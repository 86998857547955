import React from "react";
import { Box, CardMedia, Container, Typography } from "@mui/material";
import styles from "./styles"; // Import styles


const CategoryList = ({categoryList, handelCatSelection}) => {
  return (
    <Container sx={{...styles.container, padding : 0 }} >
      <Box>
        {categoryList.map((category, index) => (
          <Box id = {category.id} key={index} sx={styles.cardItem} onClick = {()=>handelCatSelection(category)}> 
            <CardMedia
              component="img"
              src={"https://placehold.co/50x50"}
              alt={category.title}
              sx={styles.media}
            />
            <Box ml={3}>
              <Typography sx={styles.title}>
                {category.name}
              </Typography>
              <Typography sx={styles.description} color="textSecondary">
                {`${1} services in ${category.name}`}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default CategoryList;
