import React from 'react';
import styles from './ServiceCard.module.css';

const ServiceCard = ({ image, title, description, imgClass }) => {
  return (
    <div className={styles.serviceBox}>
      <img src={image} alt={title} className={styles[imgClass]} />
      <h1 className={styles.serviceTitle}>{title}</h1>
      <p className={styles.serviceDescription}>{description}</p>
    </div>
  );
};

export default ServiceCard;
