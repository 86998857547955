import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles";
 
const ModalWrapper = (WrappedComponent,customStyles={}) => {
    console.log("customStyles::",customStyles);
    
  return ({ open, handleClose, ...props }) => {

    return (
      <Modal open={open} onClose={handleClose} sx={{...styles.modalOverlay}}>
        <Box
          sx={{
            ...styles.card,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            ...customStyles
          }}
        >
          <IconButton
            aria-label="Close"
            sx={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          
        {/* Render the wrapped component and pass down all props */}
        <WrappedComponent handleClose={handleClose} {...props} />
          
        </Box>
      </Modal>
    );
  };
};

export default ModalWrapper;
