import { Box, Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { useTheme } from "@mui/material/styles";
import { TextFieldStyled } from "./VendorSignupStyles";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
const BusinessProfile = () => {
  const theme = useTheme();

  const handleUploadClick = (event) => {
    const files = event.target.files;
    // Handle the uploaded files
    console.log(files);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 1.5, mb: 0, pb: 0 }}
      >
        <TextFieldStyled
          fullWidth
          variant="standard"
          margin="dense"
          label="Vendor name"
          required
          size="small"
        />
        <TextFieldStyled
          fullWidth
          variant="standard"
          margin="dense"
          label="Business name"
          required
          size="small"
        />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 1.5, mb: 0, pb: 0 }}
      >
        <TextFieldStyled
          fullWidth
          variant="standard"
          margin="dense"
          multiline
          rows={1}
          label="Business Description"
          required
          size="small"
        />
        <TextFieldStyled
          fullWidth
          variant="standard"
          margin="dense"
          label="Business specific keywords"
          multiline
          rows={1}
          required
          size="small"
        />
      </Box>
      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Year of Experience"
        required
        size="small"
      />
      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Review & Ratings"
        rows={2}
        multiline
        required
        size="small"
      />

      {/* Image Upload Buttons */}
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent:"center", gap: 1, mt: 2 }}>
        <Button
          variant="contained"
          component="label"
          startIcon={
            <FileUploadIcon sx={{ fontSize: 14, color: "#1976d2" }} />
          }
          sx={{
            backgroundColor: theme.palette.customColors.whiteBg,
            color: theme.palette.textColors.whiteBg,
            textTransform: "none",
            fontSize: "0.8rem", // Reduced font size for the button text
            padding: "4px 8px", // Adjust padding for smaller size
            boxShadow:"none",
            border:"1px solid gray",
            "&:hover": {
              backgroundColor: theme.palette.customHoverColors.whiteBg,
            },
          }}
        >
          Upload Profile Picture
          <input
            type="file"
            hidden
            accept="image/jpeg,image/png,image/heic,image/webp"
            onChange={handleUploadClick}
          />
        </Button>

        <Button
          variant="contained"
          component="label"
          startIcon={
            <AddCircleOutlineIcon sx={{ fontSize: 14, color: "#1976d2" }} />
          }
          sx={{
            backgroundColor: theme.palette.customColors.whiteBg,
            color: theme.palette.textColors.whiteBg,
            textTransform: "none",
            fontSize: "0.8rem", // Reduced font size for the button text
            padding: "4px 8px", // Adjust padding for smaller size
            boxShadow:"none",
            border:"1px solid gray",
            "&:hover": {
              backgroundColor: theme.palette.customHoverColors.whiteBg,
            },
          }}
        >
          Add Business Images
          <input
            type="file"
            hidden
            accept="image/jpeg,image/png,image/heic,image/webp"
            onChange={handleUploadClick}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default BusinessProfile;
