import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  REQUEST_SENT,
  RESEND_VERIFICATION_EMAIL_REQUEST,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  RESEND_VERIFICATION_EMAIL_FAILURE,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_REQUEST,
  OTP_VERIFICATION_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_SUCCESS,
} from '../actionTypes';

const initialState = {
  isLoggedIn: false,
  userData: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SENT:
    case REGISTER_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case OTP_VERIFICATION_REQUEST:
    case SET_NEW_PASSWORD_REQUEST:
    case RESEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload,
        error: null,
      };
    case REGISTER_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case RESEND_VERIFICATION_EMAIL_SUCCESS:
    case OTP_VERIFICATION_SUCCESS:
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case LOGIN_FAILURE:
    case REGISTER_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
    case LOGOUT_FAILURE:
    case RESEND_VERIFICATION_EMAIL_FAILURE:
    case OTP_VERIFICATION_FAILURE:
    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        isLoggedIn: false,
        userData: null,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
