import React from "react";
import "./App.css";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Sidebar } from "./components/layout";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import RoutesPage from "./route/routes";
import { ToastContainer } from "react-toastify";
import { horizontalScale as hs, verticalScale as vs } from "./utils/scaling";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components"; // Import ThemeProvider from styled-components

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    customColors: {
      darkOliveGreen: "#131C12",
      primary2: "#73A0E7",
      whiteBg: "#fffffff3",
      secondary: "#F6F5F3",
      black: "#111111",
      gray: "#666666",
      transparent: "transparent",
      primary3: "#F4A261",
      blackColor: "#000",
    },
    customHoverColors: {
      whiteBg: "#ffffff7e",
    },
    textColors: {
      whiteBg: "#11111180",
    },
  },
  typography: {
    fontFamily: ["Gilroy-Regular", "Arial", "sans-serif"].join(","),
    title2:{
      fontFamily: "Gilroy-SemiBold",
      fontSize: `${hs(47)}px`,
      fontWeight: hs(400),
      lineHeight: `${hs(57.58)}px`,
    },
    heading3: {
      fontFamily: "Gilroy-Medium",
      fontSize: hs(32),
      fontWeight: hs(400),
      lineHeight: `${hs(38.82)}px`,
      letterSpacing: "0.03em",
      color: "black",
    },
    heading4: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: hs(30),
      fontWeight: `${hs(400)}`,
      lineHeight: `${hs(26)}px`,
      color: "black",
    },
    heading6: {
      fontFamily: "Gilroy-Medium",
      fontSize: hs(18),
      fontWeight: hs(400),
      lineHeight: `${hs(21.83)}px`,
      letterSpacing: "0.03em",
      color: "black",
    },
  },
});

const App = () => {
  const auth = useSelector((state) => state.auth.userData);
  const location = useLocation();

  return (
    <MUIThemeProvider theme={theme}>
      <StyledComponentsThemeProvider theme={theme}> {/* Wrap with styled-components ThemeProvider */}
        <ToastContainer />
        <CssBaseline />
        <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
          {/* {auth?.token && <Sidebar currentPath={location.pathname} />} */}
          <RoutesPage />
        </Box>
      </StyledComponentsThemeProvider>
    </MUIThemeProvider>
  );
};

export default App;
