import React, { useState } from 'react';
import styles from './ZipcodeInput.module.css';
import Images from '../../../assets/image';

const ZipcodeInput = ({ placeholder,zipcode, setZipcode }) => {

  const handleZipcodeChange = (e) => {
    setZipcode(e.target.value);
  };

  return (
    <div className={styles.zipcodeContainer}>
      <img src={Images.locationIcon} alt="location" className={styles.icon} />
      <input
        type="text"
        placeholder={placeholder}
        value={zipcode}
        onChange={handleZipcodeChange}
        className={styles.zipcodeInput}
      />
    </div>
  );
};

export default ZipcodeInput;
