import React from "react";
import { Box, Button, Checkbox, FormControlLabel, Typography, OutlinedInput, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TextFieldStyled } from "./VendorSignupStyles";
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const categories = [
  'Category 1',
  'Category 2',
  'Category 3',
  'Category 4',
  'Category 5',
  'Category 6',
  'Category 7',
  'Category 8',
];

function getStyles(category, selectedCategories, theme) {
  return {
    fontWeight:
      selectedCategories.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    fontSize: '0.7rem', // Reduced font size
  };
}

const ServicesVendorOffers = () => {
  const theme = useTheme();
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "4%" }}>
      {/* Zip Code Field */}
      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Enter Zip Code"
        required
        size="small"
        inputProps={{ style: { fontSize: '0.7rem' } }} // Reduced font size
        InputLabelProps={{ style: { fontSize: '0.7rem' } }} // Reduced label font size
      />

      {/* Services Offered Multi-Select */}
      <FormControl sx={{ mt: 2, mb: 2, width: '100%' }}>
        <InputLabel id="services-offered-label" sx={{ fontSize: '0.7rem' }}>Services Offered</InputLabel>
        <Select
          labelId="services-offered-label"
          id="services-offered"
          multiple
          value={selectedCategories}
          onChange={handleCategoryChange}
          input={<OutlinedInput label="Services Offered" />}
          MenuProps={MenuProps}
          placeholder="Select Category"
          sx={{ fontSize: '0.7rem' }} // Reduced font size
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              style={getStyles(category, selectedCategories, theme)}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Verification Documents Upload */}
      <Typography variant="body2" sx={{ mt: 3, mb: 1, fontSize: '0.7rem' }}>
        Verification Documents
      </Typography>
      <Button
        variant="contained"
        component="label"
        startIcon={
            <FileUploadIcon sx={{ fontSize: 14, color: "#1976d2" }} />
          }
        sx={{
          backgroundColor: theme.palette.customColors.whiteBg,
          width:'fit-content',
          color: theme.palette.textColors.whiteBg,
          textTransform: "none",
          "&:hover": {
            backgroundColor: theme.palette.customHoverColors.whiteBg,
          },
          fontSize: '0.7rem', // Reduced font size
          padding: '4px 8px', // Adjusted padding to match smaller size
        }}
      >
        Upload PDF
        <input
          type="file"
          hidden
          accept="application/pdf"
        />
      </Button>


      <Box>
      </Box>  
      {/* Checkbox for Special Email Offers */}
      <FormControlLabel
        control={<Checkbox size="small"  />} 
        label="I’d like to receive special email offers and discounts."
        sx={{ mt: 1, fontSize: '0.7rem', '& .MuiFormControlLabel-label':{fontSize:"0.8rem"} }} 
      />

      {/* Checkbox for Terms of Membership */}
      <FormControlLabel
        control={<Checkbox size="small" />} // Reduced checkbox size
        label="I agree to the terms of the membership agreements."
        sx={{ mt: 1, fontSize: '0.7rem', '& .MuiFormControlLabel-label':{fontSize:"0.8rem"} }} 
      />
    </Box>
  );
};

export default ServicesVendorOffers;
