import React, { useState, useCallback } from "react";
import styles from "./ForgotPassword.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, InputCard } from "../../../../../components/common";
import images from "../../../../../assets/image";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../../../data/redux/actions/authActions";
import useNotification from "../../../../../hooks/notification";
import { forgotPasswordApi } from "../../../../../data/api/authApi";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showNotification, Notification } = useNotification();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const islogin = queryParams.get("islogin") === 'true';

  // Handle email input change
  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
    setEmailError(""); 
  }, []);

  // Submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    try {
      dispatch(forgotPassword.request());
      const response = await forgotPasswordApi(email);
      showNotification(response.message, 'success');
      dispatch(forgotPassword.success(response));
      navigate(`/verify-otp?email=${encodeURIComponent(email)}&islogin=${islogin ? 'true' : 'false'}`);
    } catch (error) {
      console.error('Forgot Page error:', error.data?.error || error.message);
      showNotification(error.data?.error || 'An error occurred', 'error');
      dispatch(forgotPassword.failure(error.data?.error || 'An error occurred'));
    }
  };

  return (
    <div className={styles.troubleLoggingIn}>
      <Notification />
      <div className={styles.content}>
        <img src={images.troubleLoggingIn} alt="Trouble logging in" />
        <h1>Trouble logging in?</h1>
        <p>Enter your email and we'll send you a code to get back into your account.</p>
        <InputCard
          placeholder="Email ID"
          label="Email Id"
          name="email"
          value={email}
          type="email"
          onChange={handleEmailChange}
          error={emailError}
        />
        <div style={{ marginTop: "1rem" }}>
          <ReCAPTCHA sitekey="6LcgzBUqAAAAAK-Eakccw4KpsZfAr_l4b6g-iLac" />
        </div>
        <Button
          onClick={handleSubmit}
          label="Send Code"
          fontSize="1rem"
          paddingVertical="1.8rem"
          paddingHorizontal=".8rem"
          margin="1rem"
        />
        <Link to="/sign-in">Back to Login</Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
