import React, { useState } from "react";
import {
  Box,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles";
import GenerateButton from "../../../../components/common/Button/GenrativeBtn";
import GenerateDescriptionModal from "./GenerateDescriptionModal";
import { ReactComponent as MagicWandIcon } from "./magic.svg";

const MAX_CHAR_COUNT = 2000; // Maximum character count

const TagInput = ({ onAddTag }) => {
  const [newTag, setNewTag] = useState("");

  const handleAddTag = (event) => {
    if (
      newTag.trim() !== "" &&
      (event.key === "Enter" || event.key === "Tab")
    ) {
      onAddTag(newTag.trim());
      setNewTag("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <TextField
        label="Create a new tag"
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyDown={handleAddTag}
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: (
            <AddIcon
              sx={{ cursor: "pointer" }}
              onClick={() => handleAddTag({ key: "Enter" })}
            />
          ),
        }}
      />
    </Box>
  );
};

const DescriptionBox = ({
  tags,
  setTags,
  description,
  setDescription,
  selectedCat,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [charCount, setCharCount] = useState(description.length);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleAddTag = (tag) => {
    if (tags.some((selectedTag) => selectedTag.tag === tag)) {
      return;
    }
    const newTag = { id: tags.length + 1, tag };
    setTags((prevTags) => [...prevTags, newTag]);
  };

  const handleRemoveTag = (tag) => {
    setTags((prevSelectedTags) =>
      prevSelectedTags.filter((selectedTag) => selectedTag.id !== tag.id)
    );
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_CHAR_COUNT) {
      setDescription(value);
      setCharCount(value.length);
    }
  };

  return (
    <Box sx={{ ...styles.descriptionBox, border: "1 solid" }}>
      <Box sx={{ position: "relative", mb: 2 }}>
        <TextField
          label="Describe your project"
          multiline
          rows={5}
          value={description}
          onChange={handleDescriptionChange}
          variant="outlined"
          fullWidth
        />
        {/* Character counter */}
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            bottom: 8,
            right: 8,
            color: charCount >= MAX_CHAR_COUNT ? "red" : "inherit",
            backgroundColor: "white",
            padding: "0 4px",
          }}
        >
          {charCount}/{MAX_CHAR_COUNT}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GenerateButton
          title={"Generate via AI"}
          handelButtonClick={handleOpenModal}
          EndIcon={
            <MagicWandIcon style={{ width: "20px", height: "20px" }} />
          }
        />
        {/* New Tag creation input */}
        <TagInput onAddTag={handleAddTag} />
      </Box>

      {/* Display tag suggestions; a user can select tags from here */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginTop: "16px",
          maxHeight: "30%",
          overflow: "auto",
        }}
      >
        {tags.map((obj) => {
          return (
            <Chip
              key={obj.id}
              label={obj.tag}
              sx={{
                border: `1px solid #73A0E7`,
                padding: "5px 12px",
                borderRadius: "18px",
                background: "#FFFFFF",
                fontFamily: "Gilroy-Medium",
                fontSize: "0.8rem",
                color: "#73A0E7",
                cursor: "pointer",
              }}
              deleteIcon={
                <CloseIcon
                  sx={{
                    fontSize: "16px",
                    color: "#73A0E7",
                    cursor: "pointer",
                  }}
                />
              }
              onDelete={() => handleRemoveTag(obj)}
            />
          );
        })}
      </Box>

      <GenerateDescriptionModal
        open={openModal}
        setOpenModal={setOpenModal}
        handleClose={handleCloseModal}
        tags={tags}
        initialDescription={description}
        selectedCat={selectedCat}
        setInitialDescription={setDescription}
      />
    </Box>
  );
};

export default DescriptionBox;
