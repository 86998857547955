import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.js";
import MCQSection from "./MCQSection.jsx";
import DescriptionBox from "./DescriptionBox.jsx";
import ImageUpload from "./ImageUpload.jsx";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { createJobApi } from "../../../../data/api/guestUserApi.js";
import ModalWrapper from "../../../../components/common/modal/modalWrapper.jsx";
import CustomButton from "../../../../components/common/Button/CustomButton.jsx";

const CreateJobModal = ({ open, handleClose, mcqs, selectedCat, zipCode }) => {
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const theme = useTheme();
  const guestUser = useSelector((state) => state.guest);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (selectedCat.tags) {
      setTags(selectedCat.tags.slice(0, 5));
    }
  }, [selectedCat.tags]);

  const handleNavigation = (direction) => {
    setCurrentPage((prevPage) => prevPage + direction);
  };

  const validateForm = () => {
    const errors = [];
    if (tags.length < 2) errors.push("Select at least two tags");
    if (description.length === 0 || description.length > 2000) errors.push("Description must be between 1 and 2000 characters");
    if (images.length < 3 || images.length > 5) errors.push("Upload 3 to 5 images");
    if (selectedOptions.length !== 6) errors.push("Fill all the MCQs");

    if (errors.length) {
      toast.warn(errors.join(", "));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("category_id", selectedCat.id);
    formData.append("zipCode", zipCode);
    formData.append("tags", tags.map((obj) => obj.tag).join(","));
    formData.append("description", description);
    formData.append("email", guestUser.email);
    images.forEach((image) => formData.append("images", image));
    formData.append("mcqs", JSON.stringify(selectedOptions));

    try {
      await createJobApi(formData);
      handleClose();
      toast.success("Job created successfully");
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data");
    }
  };

  const getHeading = () => {
    const headings = {
      3: "Please tell us a little about your project.",
      4: "Upload Pictures",
      default: "Compare Quotes from top-rated Services you required"
    };
    return headings[currentPage] || headings.default;
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Box sx={styles.cardContent}>
        <Typography variant="h5" sx={{ ...styles.titleText, textAlign: "center", marginBottom: "16px" }}>
          {getHeading()}
        </Typography>
        {currentPage <= 2 && (
          <MCQSection
            mcqs={mcqs}
            currentPage={currentPage}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        )}
        {currentPage === 3 && (
          <DescriptionBox
            tags={tags}
            setTags={setTags}
            description={description}
            setDescription={setDescription}
            selectedCat={selectedCat}
          />
        )}
        {currentPage === 4 && (
          <ImageUpload images={images} setImages={setImages} />
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
        <CustomButton onClick={() => handleNavigation(-1)} isWhite disabled={currentPage === 1}>
          Previous
        </CustomButton>
        <CustomButton onClick={currentPage === 4 ? handleSubmit : () => handleNavigation(1)} isBlack>
          {currentPage === 4 ? "Submit" : "Continue"}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ModalWrapper(CreateJobModal);
