import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GenerateButton from "../../../../components/common/Button/GenrativeBtn.jsx";
import { ReactComponent as MagicWandIcon } from "./magic.svg";
import { generateDescriptionApi } from "../../../../data/api/commonApi";
import ModalWrapper from "../../../../components/common/modal/modalWrapper.jsx";
const MAX_CHAR_COUNT = 2000;

const GenerateDescriptionModal = ({
  tags,
  initialDescription,
  setInitialDescription,
  setOpenModal,
  selectedCat,
}) => {
  const [description, setDescription] = useState(initialDescription);
  const [enhancedDescription, setEnhancedDescription] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [enhancedCharCount, setEnhancedCharCount] = useState(0);

  useEffect(() => {
    setDescription(initialDescription);
    setCharCount(initialDescription.length);
  }, [initialDescription]);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_CHAR_COUNT) {
      setDescription(value);
      setCharCount(value.length);
    }
  };
  
  const handleEnhancedDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_CHAR_COUNT) {
      setEnhancedDescription(value);
      setEnhancedCharCount(value.length);
    }
  };

  const handleGenerateDescription = async () => {
    try {
      const data = {
        categoryName: selectedCat.name,
        tags: tags,
        description: description,
      };
      let demoString = `Task ID: 1 
        Description: Measure kitchen dimensions 
        Assigned To: John Doe 
        Priority: High 
        Time: 1 hr  `;
      // const response = await generateDescriptionApi(data);
      setEnhancedDescription(demoString);
      setEnhancedCharCount(demoString.length);
      console.log("generatedDescription::", demoString);
    } catch (error) {
      console.error("Error generating description:", error);
    }
  };

  const handleContinue = () => {
    setInitialDescription(enhancedDescription);
    setOpenModal(false);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
        Generate Description using AI
      </Typography>
      <Box
        sx={{
          position: "relative",
          "& .MuiInputBase-input": {
            fontSize: "0.8rem",
            lineHeight: "1.25rem",
          },
        }}
      >
        <TextField
          label="Write your description"
          multiline
          minRows={3}
          maxRows={5}
          value={description}
          onChange={handleDescriptionChange}
          variant="outlined"
          fullWidth
        />
        {/* Position the Generate button in the bottom right corner */}
        <GenerateButton
          title={"Generate"}
          handelButtonClick={handleGenerateDescription}
          StartIcon={
            <MagicWandIcon style={{ width: "20px", height: "20px" }} />
          }
          styles={{
            position: "absolute",
            bottom: "15%",
            right: 26,
            zIndex: 1,
          }}
        />
      </Box>
      <Typography
        variant="caption"
        sx={{
          display: "block",
          textAlign: "right",
          color: charCount >= MAX_CHAR_COUNT ? "red" : "inherit",
        }}
      >
        {charCount}/{MAX_CHAR_COUNT} characters
      </Typography>
      {enhancedDescription && (
        <Box
          sx={{
            mt: 3,
            position: "relative",
            "& .MuiInputBase-input": {
              fontSize: "0.8rem",
              lineHeight: "1.25rem",
            },
          }}
        >
          <Typography variant="body1" sx={{ mb: 1 }}>
            Enhanced Description
          </Typography>
          <TextField
            multiline
            minRows={4}
            maxRows={8}
            value={enhancedDescription}
            onChange={handleEnhancedDescriptionChange}
            variant="outlined"
            fullWidth
            sx={{ position: "relative" }}
          />
          <Typography
            variant="caption"
            sx={{
              display: "block",
              textAlign: "right",
              mt: 1,
              color: enhancedCharCount >= MAX_CHAR_COUNT ? "red" : "inherit",
            }}
          >
            {enhancedCharCount}/{MAX_CHAR_COUNT} characters
          </Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: "12%",
              right: 26,
              display: "flex",
              gap: 1,
            }}
          >
            <GenerateButton
              title={"Regenerate"}
              handelButtonClick={handleGenerateDescription}
              StartIcon={
                <MagicWandIcon style={{ width: "20px", height: "20px" }} />
              }
            />
            <GenerateButton
              title={"Continue"}
              handelButtonClick={handleContinue}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const customStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  backgroundColor: "white",
  borderRadius: 2,
  boxShadow: 24,
  padding: 4,
  outline: "none",
  height:'fit-content'
};

export default ModalWrapper(GenerateDescriptionModal,customStyles);
