import { apiRequest } from "./utils.js";

export const logInApi = async (params) => {
  const { email, password } = params;
  return apiRequest("POST", "/user/auth/login", { email, password });
};

export const registerApi = async (params) => {
  const { email, name, password, mobile } = params;
  return apiRequest("POST", "/user/auth/sign-up", {
    name,
    email,
    mobile,
    password,
  });
};

export const logOutApi = async (params) => {
  return apiRequest("GET", "/api/logout");
};

export const resendVerificationEmailApi = async (email) => {
  return apiRequest("GET", `/user/auth/resend-verification/${email}`);
};

export const forgotPasswordApi = async (email) => {
  return apiRequest("POST", "/user/auth/forget-password", { email });
};

export const otpVerificationApi = async (params) => {
  const { email, otpNumber } = params;
  return apiRequest("POST", "/user/auth/otp-check", { email, otpNumber });
};

export const setNewPassordApi = async (params) => {
  const { email, otpNumber, newPassword, confirmNewPassword } = params;
  return apiRequest("POST", "/user/auth/reset-password", {
    email,
    otpNumber,
    confirmNewPassword,
    newPassword,
  });
};
