import React from "react";
import styles from "./Verification.module.css";
import images from "../../../../../assets/image";
import { Button } from "../../../../../components/common";
import OtpInput from "../../../../../components/common/Input/OtpInput";
import { useNavigate } from "react-router-dom";

const Verification = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.verificationContainer}>
      <div className={styles.content}>
        <img src={images.verifyLock} alt="lock" />
        <h1>We’ve sent a code to t******@gmail.com</h1>
        <h1>You need to enter the code </h1>
        <OtpInput />
        <Button
          onClick={() => navigate("/set-password")}
          label="Submit"
          fontSize="1rem"
          paddingVertical="1.8rem"
          paddingHorizontal=".8rem"
          margin="1rem 0"
        />
        <p>Resend Code</p>
      </div>
    </div>
  );
};

export default Verification;
