import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CustomButton = ({ children, onClick, isBlack, isWhite, disabled, customCss={} }) => {
  const theme = useTheme();

  const buttonStyles = {
    padding: "8px 16px",
    borderRadius: "4px",
    width: "49%",
    textTransform: "none",
    backgroundColor: isBlack ? "black" : isWhite ? theme.palette.customColors.whiteBg : theme.palette.primary.main,
    color: isBlack ? "white" : "black",
    "&:hover": {
      backgroundColor: isBlack ? "black" : isWhite ? theme.palette.customHoverColors.whiteBg : theme.palette.primary.dark,
      opacity: isBlack ? 0.8 : 1,
    },
    ...customCss
  };

  return (
    <Button variant="contained" sx={buttonStyles} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default CustomButton;
