import React, { useState, useCallback } from "react";
import styles from "./Login.module.css";
import Images from "../../../../../assets/image";
import { useNavigate } from "react-router-dom";
import { Button, InputCard } from "../../../../../components/common";
import { Link } from "react-router-dom";
import { ReactComponent as Google } from "../../../../../assets/images/SSO-google.svg";
import { ReactComponent as Apple } from "../../../../../assets/images/SSO-apple.svg";
import useNotification from "../../../../../hooks/notification";
import { useDispatch } from "react-redux";
import { login } from "../../../../../data/redux/actions/authActions";
import { logInApi } from "../../../../../data/api/authApi";

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showNotification, Notification } = useNotification();

  const [form, setForm] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!form.email) {
      errors.email = "Email is required.";
    } else if (!emailPattern.test(form.email)) {
      errors.email = "Invalid email address.";
    }

    if (!form.password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      dispatch(login.request());
      const response = await logInApi(form);
      dispatch(login.success(response));
      // if (form.rememberMe) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
      // }
      localStorage.setItem('token', JSON.stringify(response.data.token));
      showNotification(response.message, 'success');
      navigate('/account-settings');
    } catch (error) {
      console.error('Login Page error:', error.data?.error || error.message);
      showNotification(error.data?.error || 'An error occurred', 'error');
      dispatch(login.failure(error.data?.error || 'An error occurred'));
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.backgroundImage}></div>
      <Notification />
      <div className={styles.content}>
        <div className={styles.leftSection}>
          <div className={styles.logo}>Home Service</div>
          <div className={styles.spacer}></div>
          <h1>
            <span>Welcome Back!</span> Access Your Trusted Local Service Providers
          </h1>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.navigation}>
            <img
              src={Images.backArrow}
              alt="Back Arrow"
              onClick={() => navigate("/")}
              className={styles.backArrow}
            />
            <h1>Sign In</h1>
          </div>
          <div className={styles.form}>
            <InputCard
              placeholder="Email ID"
              label="Email Id"
              name="email"
              value={form.email}
              type="email"
              onChange={handleChange}
              error={formErrors.email}
            />
            <InputCard
              placeholder="Password"
              label="Password"
              name="password"
              value={form.password}
              type="password"
              onChange={handleChange}
              error={formErrors.password}
            />
            <div className={styles.forgotSection}>
              <div className={styles.rememberMe}>
                <input
                  type="checkbox"
                  checked={form.rememberMe}
                  onChange={handleChange}
                  name="rememberMe"
                  id="rememberMe"
                />
                <label htmlFor="rememberMe">Remember Me</label>
              </div>
              <Link to="/forgot-password" className={styles.forgotPassword}>
                Forgot Password
              </Link>
            </div>
            <Button
              label="Sign In"
              fontSize="1.2vmax"
              paddingHorizontal="1vmax"
              width="100%"
              onClick={handleSignIn}
            />
          </div>
          <div className={styles.separator}>
            <div className={styles.line} />
            <p>OR</p>
            <div className={styles.line} />
          </div>
          <div className={styles.ssobutton}>
            <div className={styles.continueAsGuest} onClick={() => { }}>
              <Google /> Sign in with Google
            </div>
            <div className={styles.continueAsGuest} onClick={() => { }}>
              <Apple /> Sign in with Apple
            </div>
            <div className={styles.continueAsGuest} onClick={() => { }}>
              Continue as Guest
            </div>
          </div>
          <div className={styles.signupPrompt}>
            Don't have an account?{" "}
            <span onClick={() => navigate('/sign-up')}>Sign Up</span>
          </div>
        </div>
      </div>
      {Notification}
    </div>
  );
};

export default Login;
