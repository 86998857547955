module.exports = {
    modalOverlay: {
      display: "flex",
      alignItems: "center", 
      justifyContent: "center",
    },
    card: {
      backgroundColor: "#fff",
      padding: "2rem 3rem",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "55vw",
      height:"550px",
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      top: "8px",
      right: "8px",
      color: "#7a7a7a",
    }
  };
  