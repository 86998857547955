import React, { useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import CustomStepper from "../../../../../components/common/Stepper/CustomStepper";
import {
  FormContainer,
  LeftArrowIcon,
  StepperContainer,
  FormFieldsContainer,
  ButtonContainer,
  LeftBackgroundBox,
  RightBackgroundBox,
  LeftBackgroundContentsBox,
} from "./VendorSignupStyles";
import CustomButton from "../../../../../components/common/Button/CustomButton";
import { verticalScale } from "../../../../../utils/scaling";
import PersonalDetails from "./PersonalDetails.jsx";
import BusinessProfile from "./BusinessProfile.jsx";
import ServicesVendorOffers from "./ServicesVendorOffers";

const VendorSignup = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Handle form submission or sign-up logic here
      console.log("Sign up completed");
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <PersonalDetails />;
      case 1:
        return <BusinessProfile />;
      case 2:
        return <ServicesVendorOffers />;
      default:
        return null;
    }
  };

  return (
    <Box position="relative" height="100vh" width="100vw">
      <LeftBackgroundBox />

      {/* Left content */}
      <LeftBackgroundContentsBox>
        <Typography variant="heading4" width={`${verticalScale(102)}px`}>
          Home Service
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Typography
          variant="title2"
          width={`${verticalScale(800)}px`}
          sx={{ color: "white" }}
        >
          Grow Your Business: Connect with Homeowners Today
        </Typography>
      </LeftBackgroundContentsBox>

      <RightBackgroundBox>
        <FormContainer>
          <Box display="flex" alignItems="center" width={"100%"}>
            <LeftArrowIcon />
            <Typography variant="heading3" fontWeight="bold" ml={1}>
              Vendor's Sign Up
            </Typography>
          </Box>

          <StepperContainer>
            <CustomStepper
              activeStep={activeStep}
              steps={[
                "Personal Details",
                "Business Profile",
                "Services Vendor Offers",
              ]}
            />
          </StepperContainer>

          <FormFieldsContainer>
            {getStepContent(activeStep)}
          </FormFieldsContainer>

          <ButtonContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {activeStep > 0 && (
                <CustomButton
                  isWhite
                  onClick={handleBack}
                  customCss={{ width: "49%" }}
                >
                  Previous
                </CustomButton>
              )}
              <CustomButton
                isBlack
                onClick={handleNext}
                customCss={{ width: activeStep > 0 ? "49%" : "100%" }}
              >
                {activeStep === 2 ? "Sign Up" : "Next"}
              </CustomButton>
            </Box>

            <Typography variant="body2" mt={1} textAlign="center">
              Already have an account?{" "}
              <Link href="#" color="primary">
                Sign In
              </Link>
            </Typography>
          </ButtonContainer>
        </FormContainer>
      </RightBackgroundBox>
    </Box>
  );
};

export default VendorSignup;
