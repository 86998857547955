
const { innerWidth: width, innerHeight: height } = window;
console.log(width);
console.log(height);

const guidelineBaseWidth = 1920; 
const guidelineBaseHeight = 1080; 

const horizontalScale = (size) => (width / guidelineBaseWidth) * size;
const verticalScale = (size) => (height / guidelineBaseHeight) * size;
const moderateScale = (size, factor = 0.5) =>
  size + (horizontalScale(size) - size) * factor;

export { horizontalScale, verticalScale, moderateScale };
