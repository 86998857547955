import { Box, Button, Container, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import styles from './Support.module.css'
import { ReactComponent as PaperIllustrater } from "../../../../assets/images/support-vector.svg";
import { ReactComponent as FluentBot } from "../../../../assets/images/fluent-bot.svg";

const Support = () => {
  return (
    <Box className={styles.supportContainer}>
      <Typography variant="h6" component="h2">
        Support
      </Typography>
      <Typography variant="p" component="p">
        Take Support here.
      </Typography>
      <Box className={styles.content}>
        <PaperIllustrater />
        <Typography variant="h6" component="h2">
        Are you facing any problem?
        </Typography>
        <Typography variant="p" component="p">
        If you need instant support then use live chat option to reach us quickly
        </Typography>
        <Typography variant="p" component="p">
        Our support will reply as soon as possible after you send us a message
        </Typography>
        <Button className={styles.tellMeProblem} > <FluentBot />Tell me Problem</Button>
      </Box>
    </Box>
  );
};

export default Support;
