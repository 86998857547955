import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  email: '',
  city: '',
};

const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestInfo: (state, action) => {
      const { name, email, city } = action.payload;
      state.name = name;
      state.email = email;
      state.city = city;
      localStorage.setItem('guestUser', JSON.stringify({ name, email, city }));
    },
  },
});

export const { setGuestInfo } = guestSlice.actions;
export default guestSlice.reducer;
