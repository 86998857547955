import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../features/home/Home";
import {
  ForgotPassword,
  Login,
  SetNewPassword,
  Signup,
  VerifyEmail,
  VerifyOtp,
} from "../features/user/Authentication";
import {VendorSignup} from "../features/vendor/Authentication"
import { AccountSettings } from "../features/user";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "../features/user/AccountSettings/pages/resetPassword/ResetPassword";

const RoutesPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/sign-in" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/set-password" element={<SetNewPassword />} />
      <Route path="/verify-otp" element={<VerifyOtp />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route
        path="/account-settings"
        element={<PrivateRoute element={<AccountSettings />} />}
      />
      <Route
        path="/reset-password"
        element={<PrivateRoute element={<ResetPassword />} />}
      />
      {/* vendors */}
      <Route path="/vendor/sign-up" element={<VendorSignup />} />
    </Routes>
  );
};

export default RoutesPage;
