import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const LanguageSelect = ({className}) => {
  const [language, setLanguage] = React.useState("");

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <TextField
    className={className}
      select
      fullWidth
      label={language ? " " : "Select Language"}
      value={language}
      onChange={handleChange}
      variant="outlined"
      InputLabelProps={{
        shrink: false,
      }}
    >
      <MenuItem value="">Select Language</MenuItem>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="es">Spanish</MenuItem>
    </TextField>
  );
};

export default LanguageSelect;
