import home1 from "./images/home-1.svg";
import home2 from "./images/home-2.svg";
import home3 from "./images/home-3.svg";
import homeMen from "./images/home-men.png";
import homeGradient  from "./images/home-gradient.svg";
import searchIcon from "./images/mingcute_search-line.png";
import locationIcon from "./images/system-uicons_location.png";
import frame1 from "./images/Frame-2-1.png";
import framePaint from "./images/Frame-2-Paint.png";
import frame3 from "./images/Frame-2-3.png";
import section21 from "./images/section-2-1.png";
import section22 from "./images/section-2-2.png";
import howItWorks from "./images/how_it_work.png";
import advantage from "./images/advantage.png";
import provide1 from "./images/provide-1.png";
import provide2 from "./images/provide-2.png";
import provide3 from "./images/provide-3.png";
import linkedin from "./images/linkdin.png";
import facebook from "./images/facebook.png";
import twitter from "./images/twitter.png";
import google from "./images/google-play.png";
import apple from "./images/apple-store.png";
import backArrow from "./images/back-arrow.png";
import bottomVector from "./images/bottom-vector.png";
import dropdownBlue from "./images/dropdown-blue.png";
import trendingSearch from "./images/trending-search.png";
import userProfile from "./images/user-profile.png";
import check from "./images/check.png";
import troubleLoggingIn from "./images/verify-email.png";
import verifyLock from "./images/verify-otp.png";
import password from "./images/password.png";
import invisible from "./images/invisible.png";
import visible from "./images/visible.svg";
import blob from "./images/blob-2.png";
import back from "./images/back.png";
import test from "./images/test.jpeg";
import handHolding from './images/hand-holding-quotation-mark 1.png'
import peopleWithInternet from './images/people-with-internet-browser (1) 1.png'
import howItWork from './images/how-it-work.png'
import ourAdvantage from './images/our-advantage.png'
import auth from './images/auth-bg.png'
import emailVerify from './images/email-verify.png'

const images = { 
    home1, 
    home2, 
    home3, 
    auth,
    homeGradient, 
    homeMen, 
    searchIcon, 
    locationIcon, 
    frame1, 
    framePaint, 
    frame3, 
    section21, 
    section22, 
    howItWorks, 
    advantage, 
    provide1, 
    provide2, 
    provide3, 
    linkedin, 
    facebook, 
    twitter,
    google,
    apple,
    backArrow,
    bottomVector,
    dropdownBlue,
    trendingSearch,
    userProfile,
    check,
    troubleLoggingIn,
    verifyLock,
    password,
    invisible,
    visible,
    blob,
    back,
    test,
    handHolding,
    peopleWithInternet,
    ourAdvantage,
    howItWork,
    emailVerify
};

export default images; 