import React, { useState } from "react";
import styles from "./VerifyOtp.module.css";
import images from "../../../../../assets/image";
import { Button } from "../../../../../components/common";
import OtpInput from "../../../../../components/common/Input/OtpInput";
import { useLocation, useNavigate } from "react-router-dom";
import { maskEmail } from "../../../../../utils/helpers";
import { forgotPassword, otpVerification } from "../../../../../data/redux/actions/authActions";
import { forgotPasswordApi, otpVerificationApi } from "../../../../../data/api/authApi";
import { useDispatch } from "react-redux";
import useNotification from "../../../../../hooks/notification";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const islogin = queryParams.get("islogin") === 'true';
  const maskedEmail = maskEmail(email);
  const { showNotification, Notification } = useNotification();
  const dispatch = useDispatch();

  const handleChange = (newOtp) => {
    setOtp(newOtp);
  };

  const handleComplete = async (text) => {
    try {
      dispatch(otpVerification.request());
      const response = await otpVerificationApi({ email, otpNumber: text });
      showNotification(response.message, "success");
      dispatch(otpVerification.success(response));
      navigate(`/set-password?email=${email}&otpNumber=${text}&islogin=${islogin}`);
    } catch (error) {
      console.error("OTP verification error:", error.data?.error || error.message);
      showNotification(error.data?.error || "An error occurred", "error");
      dispatch(otpVerification.failure(error.data?.error || "An error occurred"));
    }
  };

  const handleSubmit = async () => {
    if (!otp || otp.trim().length !== 4) {
      showNotification("Please enter a valid OTP", "error");
      return;
    }
    try {
      dispatch(otpVerification.request());
      const response = await otpVerificationApi({ email, otpNumber: otp });
      showNotification(response.message, "success");
      dispatch(otpVerification.success(response));
      navigate(`/set-password?email=${email}&otpNumber=${otp}&islogin=${islogin}`);
    } catch (error) {
      console.error("OTP submission error:", error.data?.error || error.message);
      showNotification(error.data?.error || "An error occurred", "error");
      dispatch(otpVerification.failure(error.data?.error || "An error occurred"));
    }
  };

  const resendOtp = async () => {
    try {
      dispatch(forgotPassword.request());
      const response = await forgotPasswordApi(email);
      showNotification(response.message, "success");
      dispatch(forgotPassword.success(response));
    } catch (error) {
      console.error("Resend OTP error:", error.data?.error || error.message);
      showNotification(error.data?.error || "An error occurred", "error");
      dispatch(forgotPassword.failure(error.data?.error || "An error occurred"));
    }
  };

  return (
    <div className={styles.verificationContainer}>
      <Notification />
      <div className={styles.content}>
        <img src={images.verifyLock} alt="lock" />
        <h1>We’ve sent a code to {maskedEmail}</h1>
        <h2>You need to enter the code below</h2>
        <OtpInput
          numInputs={4}
          value={otp}
          onChange={handleChange}
          onComplete={handleComplete}
        />
        <Button
          onClick={handleSubmit}
          label="Submit"
          fontSize="1rem"
          paddingVertical="1.8rem"
          paddingHorizontal=".8rem"
          margin="1rem 0"
        />
        <p onClick={resendOtp} className={styles.resendLink}>Resend Code</p>
      </div>
    </div>
  );
};

export default VerifyOtp;
