import { useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

const useNotification = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const showNotification = useCallback((msg, severityType = 'info') => {
    setMessage(msg);
    setSeverity(severityType);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const Notification = () => (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );

  return { showNotification, Notification };
};

export default useNotification;
