import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Security.module.css";
import { EditableInput } from "../../../../components/common/Input";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../data/redux/actions/profileActions";
import { updateProfileApi } from "../../../../data/api/profileApi";
import useNotification from "../../../../hooks/notification";
import { useNavigate } from "react-router-dom";

const Security = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.userData.token);
  const profile = useSelector((state) => state.profile.profileData);
  const { showNotification, Notification } = useNotification();

  const [securityDeteails, setSecurityDeteails] = useState({
    email: profile?.email,
    password: profile?.password,
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleValueChange = (field, value) => {
    setSecurityDeteails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  const HandleUpdateDetails = async () => {
    const formData = new FormData();
    formData.append("email", securityDeteails.email);
    try {
      dispatch(updateProfile.request());
      const response = await updateProfileApi(formData, token);
      showNotification(response.message,'success')
      dispatch(updateProfile.success(response.data));
    } catch (error) {
      console.error("Personal detail update error:", error);
      showNotification(error.data.error,'error')
      dispatch(updateProfile.failure(error?.data?.error));
    }
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between',width: '80%'}}>
        <Notification />
      <Box>
        <Typography variant="h6" component="h2">
          Profile
        </Typography>
        <Typography variant="p" component="p">
          Update Security details here.
        </Typography>
      </Box>
      <Box>
        <Button variant="text" onClick={HandleUpdateDetails}>Update</Button>
      </Box>
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "2px solid #d9d9d98a",
          margin: "10px 0",
        }}
      />
      <Box className={styles.inputBox}>
        <Typography variant="h6" component="h5">
          Email Id
        </Typography>
        <EditableInput
          initialValue={securityDeteails.email}
          onChange={(val) => handleValueChange("email", val)}
          variant="filled"
        />
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "2px solid #d9d9d98a",
          margin: "10px 0",
        }}
      />
      <Box className={styles.inputBox}>
        <Typography variant="h6" component="h5">
          Password
        </Typography>
        <EditableInput
          initialValue={securityDeteails.password}
          variant="filled"
          type="Password"
          isIcon={false}
        />
      </Box>
      <Box className={styles.forgotPassword}>
        <Typography sx={{cursor: 'pointer'}} variant="h6" component="h5" onClick={()=>navigate('/forgot-password?islogin=true')}>
          Forgot Password
        </Typography>
        <Typography sx={{cursor: 'pointer'}} variant="h6" component="h5" onClick={()=>navigate('/reset-password')}>
          Change Password
        </Typography>
      </Box>
      <Divider
        sx={{
          width: "100%",
          borderBottom: "2px solid #d9d9d98a",
          margin: "10px 0",
        }}
      />
      <Box className={[styles.inputBox, styles.toggle]}>
        <Typography variant="h6" component="h5">
          Two Factor Authentication
        </Typography>
        <FormControlLabel
          control={<Switch checked={isChecked} onChange={handleSwitchChange} />}
        />
      </Box>
    </Box>
  );
};

export default Security;
