import React, { useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useTheme } from '@mui/material/styles';

const ImageUpload = ({ images, setImages }) => {
  const theme = useTheme();

  const handleUploadClick = (event) => {
    const files = event.target.files;
    if (files.length + images.length <= 5) {
      const fileArray = Array.from(files);
      setImages((prevImages) => prevImages.concat(fileArray));
    } else {
      alert('You can upload a maximum of 5 images.');
    }
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Box 
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "340px"
      }}
    >
      <Box 
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 2,
          maxWidth: 500,
          mx: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 3
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Please upload pictures with a maximum size of 10 MB each. Accepted formats are: JPEG, PNG, HEIC, and WEBP.
        </Typography>

        {images.length === 0 ? (
          <Box 
            sx={{
              border: '2px dashed',
              borderColor: 'text.disabled',
              borderRadius: 2,
              p: 3,
              mt: 2,
              maxWidth: '40%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              background: '#f5f5f5'
            }}
          >
            <DescriptionIcon sx={{ fontSize: 80, mb: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Images of the location
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<DescriptionIcon sx={{ fontSize: 20, color:"#1976d2" }} />}
              sx={{
                mt: 2,
                backgroundColor: theme.palette.customColors.whiteBg,
                color: theme.palette.textColors.whiteBg,
                textTransform: 'none', 
                '&:hover': {
                  backgroundColor: theme.palette.customHoverColors.whiteBg,
                },
                fontSize: '.8rem'
              }}
            >
              Upload
              <input 
                type="file" 
                hidden 
                multiple 
                accept="image/jpeg,image/png,image/heic,image/webp"
                onChange={handleUploadClick}
              />
            </Button>
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Box 
              sx={{ 
                display: 'flex', 
                overflowX: 'auto', 
                gap: 2, 
                py: 2 
              }}
            >
              {images.map((file, index) => (
                <Box 
                  key={index}
                  sx={{ 
                    position: 'relative', 
                    minWidth: 100, 
                    height: 100, 
                    background: `url(${URL.createObjectURL(file)}) center/cover no-repeat`, 
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                >
                  <IconButton 
                    sx={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      color: 'error.main',
                    }}
                    size="small"
                    onClick={() => handleRemoveImage(index)}
                  >
                    &times;
                  </IconButton>
                </Box>
              ))}
            </Box>
             { images.length <5 ? <Button
              variant="contained"
              component="label"
              startIcon={<DescriptionIcon sx={{ fontSize: 20, color:"#1976d2" }} />}
              sx={{
                mt: 2,
                backgroundColor: theme.palette.customColors.whiteBg,
                color: theme.palette.customColors.black,
                textTransform: 'none', 
                '&:hover': {
                  backgroundColor: theme.palette.customHoverColors.whiteBg,
                },
                fontSize: '.8rem'
              }}
            >
              Upload More Images
              <input 
                type="file" 
                hidden 
                multiple 
                accept="image/jpeg,image/png,image/heic,image/webp"
                onChange={handleUploadClick}
              />
            </Button>:'' } 
            
          </Box>
        )}

        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          {images.length} {images.length === 1 ? 'image' : 'images'} uploaded. {images.length < 3 ? `Please upload ${3 - images.length} more image(s).` : images.length > 5 ? 'You have exceeded the maximum limit of 5 images.' : ''}
        </Typography>
      </Box>
    </Box>
  );
};

export default ImageUpload;
