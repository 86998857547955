import React from "react";
import Images from "../../../assets/image";
import styles from "./ServiceSearch.module.css";

const ServiceSearch = ({
  placeholder,
  onClick,
  onChange,
  value,
  inputStyles = {},
  containerStyles = {},
}) => {
  return (
    <div className={styles.serviceContainer} style={containerStyles}>
      <img src={Images.searchIcon} alt="search" className={styles.icon} />
      <input
        onClick={onClick}
        value={value}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        className={styles.serviceInput}
        style={inputStyles}
      />
    </div>
  );
};

export default ServiceSearch;
