import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URI;

export const apiRequest = async (method, url, data, token) => {
  try {
    console.log(BASE_URL, "base url");
    const response = await axios({
      method,
      url: `${BASE_URL}${url}`,
      params: method === "GET" ? data : {},
      data: method === "POST" && data instanceof FormData ? data : data,
      headers: {
        "Content-Type": data instanceof FormData ? "multipart/form-data" : "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
    return response.data;
  } catch (error) {
    console.error("API request error:", error);
    throw error.response;
  }
};