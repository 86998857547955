import React from "react";
import styles from "./SectionThree.module.css";
import Images from "../../../../assets/image";

const SectionThree = () => {
  return (
    <div className={styles.container}>
      <HowItWorksSection />
      <OurAdvantagesSection />
    </div>
  );
};

const HowItWorksSection = () => (
  <div className={styles.work}>
    <img src={Images.howItWork} alt="How it works" className={styles.image} />
    <div className={styles.content}>
      <h1 className={styles.title}>How it Works</h1>
      <p className={styles.description}>
        Simply search for the service you need, enter your zip code, and connect
        with trusted local service providers to get the job done quickly and
        efficiently.
      </p>
    </div>
  </div>
);

const OurAdvantagesSection = () => (
  <div className={styles.advantage}>
    <div className={styles.content}>
      <h1 className={styles.title}>Our Advantages</h1>
      <p className={styles.advantageList}>
        Experience the benefits of using Home Service:
      </p>
      <li className={styles.advantageList}>
        <span>Wide Range of Services:</span> From home repairs to professional
        services, we have you covered.
      </li>
      <li className={styles.advantageList}>
        <span>Trusted Providers:</span> Connect with verified service providers
        in your area.
      </li>
      <li className={styles.advantageList}>
        <span>User-Friendly Interface:</span> Easily navigate and find what you
        need.
      </li>
    </div>
    <img src={Images.ourAdvantage} alt="Advantage" className={styles.image} />
  </div>
);

export default SectionThree;
