import React from 'react';
import styles from './ServiceProvide.module.css';
import Images from '../../../../assets/image'; 


const ServiceProvide = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Services We Provide</h1>
        <h4 className={styles.subtitle}>Home Services at Your Doorstep!</h4>
        <p className={styles.description}>Explore a variety of services designed to meet all your needs</p>
      </div>
      <div className={styles.serviceFrame}>
        <div className={styles.serviceList}>
          <ServiceBox image={Images.provide1} title="Home Repairs" description="Quick fixes and expert repairs for every part of your home." />
          <ServiceBox image={Images.provide2} title="Cleaning Services" description="Sparkling clean homes with our professional cleaners." />
          <ServiceBox image={Images.provide3} title="Painting" description="Beautiful, professional painting for every room." />
        </div>
      </div>
    </div>
  );
};

const ServiceBox = ({ image, title, description }) => {
  return (
    <div className={styles.box}>
      <img src={image} alt={title} className={styles.image} />
      <h2 className={styles.boxTitle}>{title}</h2>
      <p className={styles.boxDescription}>{description}</p>
    </div>
  );
};

export default ServiceProvide;
