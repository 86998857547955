import React from 'react';
import styles from './Review.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';


const Review = () => {
    const reviews = [
        {
          text: "Thank you for building such an empowering tool, especially for designers! The site went from Figma to Framer in less than a week.",
          name: "Kayla Ray",
          handle: "@kayray",
          avatar: "https://s3-alpha-sig.figma.com/img/4eb3/a852/e005083699959cd2df77d014a22ed7a6?Expires=1721606400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MibyGjkrghnl8gZB9H1YRL2ul4yLLr95Gto3wL39wj6zE25GrQsSFpYKuRczpUCnr8fvBiA1E-KBTFUHSRipYt6yVoGelDy-gghNGwx-SwvVUw4IZvO50oI6YcoOH2kLluDEBcYAVYUs6k5EU7j6KmhEd6IrhdsUrC1BEVsUcIpeJKbwiSZ6g25UJXbREWITOkhzpJIi4y6mqtC3ojYRqZhl3zFNCE22AIuuMRtsXtvf79Q2d4yIPRONKSln9pPzii2D0xhDmgvPV9I21cFZcTbKaMvhUtQ8J1csy-C-WHgihcL8cdh5qGvGm3zwLAo91MSF3cswPNFG0IkDNDPUIg__",
          rating: 4
        },
        {
          text: "This tool has transformed my workflow. I can't imagine going back to how things were before.",
          name: "Alex Johnson",
          handle: "@alexj",
          avatar: "https://via.placeholder.com/50",
          rating: 2
        },
        {
          text: "Incredible service and support. My productivity has skyrocketed thanks to this amazing tool.",
          name: "Sara Smith",
          handle: "@sara_s",
          avatar: "https://via.placeholder.com/50",
          rating: 5
        }
      ];

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

  return (
    <div className={styles.ratingReviewContainer}>
    <h2 className={styles.sectionTitle}>Ratings and Reviews</h2>
    <Slider {...settings}>
      {reviews.map((review, index) => (
        <div key={index} className={styles.reviewSlide}>
          <p className={styles.reviewText}>“{review.text}”</p>
          <div className="stars">
            {[...Array(5)].map((star, i) => (
              <FaStar key={i} color={i < review.rating ? "#FFCE31" : "transparent"} size={40} />
            ))}
          </div>
          <div className={styles.userInfo}>
           <div className={styles.userProfile}>
             <img src={review.avatar} alt="User Avatar" />
           </div>
            <div className={styles.userDetails}>
              <p className={styles.userName}>{review.name}</p>
              <p className={styles.userHandle}>{review.handle}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
  )
}

export default Review