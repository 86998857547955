import { 
    FETCH_PROFILE_REQUEST, 
    FETCH_PROFILE_SUCCESS, 
    FETCH_PROFILE_FAILURE, 
    UPDATE_PROFILE_REQUEST, 
    UPDATE_PROFILE_SUCCESS, 
    UPDATE_PROFILE_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
  } from '../actionTypes';
  import { createAction } from './utils';
  
  
  // Action creators for fetch profile
  export const fetchProfile = createAction({
    REQUEST: FETCH_PROFILE_REQUEST,
    SUCCESS: FETCH_PROFILE_SUCCESS,
    FAILURE: FETCH_PROFILE_FAILURE,
  });
  
  // Action creators for updateProfile
  export const updateProfile = createAction({
    REQUEST: UPDATE_PROFILE_REQUEST,
    SUCCESS: UPDATE_PROFILE_SUCCESS,
    FAILURE: UPDATE_PROFILE_FAILURE,
  });

    // Action creators for updateProfile
    export const chnagePassword = createAction({
      REQUEST: CHANGE_PASSWORD_REQUEST,
      SUCCESS: CHANGE_PASSWORD_SUCCESS,
      FAILURE: CHANGE_PASSWORD_FAILURE,
    });