module.exports = {
    modalOverlay: {
      display: "flex",
      alignItems: "center", 
      justifyContent: "center",
    },
    card: {
      backgroundColor: "#fff",
      padding: "2rem 3rem",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      width: "55vw",
      height:"550px",
      position: "relative",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      gap: "1.25rem",
      // padding: "1rem 2rem",
    },
    cardBody:{
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      padding: "0rem 1rem 0rem 1rem",
      overflow:"auto"
    },
    closeButton: {
      position: "absolute",
      top: "8px",
      right: "8px",
      color: "#7a7a7a",
    },
    text: {
      color: "#6b7280",
      fontSize:'1rem'
    },
    button: {
      padding: "8px 16px",
      borderRadius: "4px",
    },
    titleText: {
      color: "#000000",
      fontFamily: "Gilroy-Medium",
      fontSize: "1.4rem",
    },
    submitButton: {
      backgroundColor: "black",
      color: "white",
      width:"49%",
      textTransform:'none',
      "&:hover": {
        backgroundColor: "black",
        opacity: 0.8,
      },
    },
    previousButton: {
      border: "1px solid",
      "&:hover": {
        backgroundColor: "#73A0E7",
      },
    },
    smallText: {
        fontSize: "0.7rem",
    },
    aiButton:{
        fontFamily: "Gilroy-Medium",
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '30px',
        textAlign: 'left',
        backgroundColor:'red'
    },
    descriptionBox: {
        display: "flex",
        flexDirection: 'column',
        gap: '0.5rem',
    }
  };
  