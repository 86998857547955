import React, { useEffect, useState, useCallback } from "react";
import styles from "./Signup.module.css";
import Images from "../../../../../assets/image";
import { useNavigate } from "react-router-dom";
import { Button, InputCard } from "../../../../../components/common";
import { PasswordRequirements, TermsCheckbox } from "../../components";
import { ReactComponent as Google } from "../../../../../assets/images/SSO-google.svg";
import { ReactComponent as Apple } from "../../../../../assets/images/SSO-apple.svg";
import { useDispatch } from "react-redux";
import { registerApi } from "../../../../../data/api/authApi";
import { register } from "../../../../../data/redux/actions/authActions";
import useNotification from "../../../../../hooks/notification";

const initialRequirements = [
  { text: "One letter (a-z)", met: false },
  { text: "10 characters minimum", met: false },
  { text: "One number (0-9)", met: false },
  { text: 'No more than 2 repeated characters in a row ("oo" works but not "ooo")', met: false },
  { text: "One special character", met: false },
];

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showNotification, Notification } = useNotification();
  
  const [requirements, setRequirements] = useState(initialRequirements);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    password: '',
    mobile: ''
  });

  useEffect(() => {
    checkRequirements(form.password);
  }, [form.password]);

  const checkRequirements = useCallback((password) => {
    const updatedRequirements = [
      { text: "One letter (a-z)", met: /[a-z]/i.test(password) },
      { text: "10 characters minimum", met: password.length >= 10 },
      { text: "One number (0-9)", met: /\d/.test(password) },
      { text: 'No more than 2 repeated characters in a row ("oo" works but not "ooo")', met: !/(.)\1\1/.test(password) },
      { text: "One special character", met: /[!@#$%^&*(),.?":{}|<>]/.test(password) },
    ];
    setRequirements(updatedRequirements);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = validateForm(form);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      dispatch(register.request());
      const response = await registerApi(form);
      showNotification(response.message, 'success');
      dispatch(register.success(response));
      navigate(`/verify-email?email=${response.data.email}`);
    } catch (error) {
      console.error('Sign Up error:', error.data?.error || error.message);
      showNotification(error.data?.error || 'An error occurred', 'error');
      dispatch(register.failure(error.data?.error || 'An error occurred'));
    }
  };

  const validateForm = (form) => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!form.name) errors.name = 'Name is required.';
    if (!form.email) errors.email = 'Email is required.';
    if (form.email && !emailPattern.test(form.email)) errors.email = 'Invalid email address.';
    if (!form.password) errors.password = 'Password is required.';
    if (!form.mobile) errors.mobile = 'Mobile number is required.';

    const unmetRequirements = requirements.filter((req) => !req.met);
    if (form.password && unmetRequirements.length > 0) {
      errors.password = 'Password does not meet all requirements.';
    }

    return errors;
  };

  return (
    <div className={styles.signupContainer}>
      <div className={styles.backgroundImage}></div>
      <Notification />
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.logo}>Home Service</div>
          <div className={styles.space}></div>
          <h1>Join Us and Find Trusted Experts for Your Home Needs</h1>
        </div>
        <div className={styles.right}>
          <div className={styles.signupNav}>
            <img
              src={Images.backArrow}
              alt="Back Arrow"
              onClick={() => navigate("/")}
            />
            <h1>Let's Create Your Account</h1>
          </div>

          <div className={styles.form}>
            <InputCard
              placeholder="Full Name"
              label="Full Name"
              name="name"
              value={form.name}
              onChange={handleChange}
              error={formErrors.name}
            />
            <InputCard
              placeholder="Contact Number"
              label="Contact No."
              name="mobile"
              value={form.mobile}
              onChange={handleChange}
              error={formErrors.mobile}
            />
            <InputCard
              placeholder="Email ID"
              label="Email Id"
              name="email"
              value={form.email}
              type="email"
              onChange={handleChange}
              error={formErrors.email}
            />
            <InputCard
              placeholder="Password"
              label="Password"
              name="password"
              value={form.password}
              type="password"
              onChange={(e) => {
                handleChange(e);
                checkRequirements(e.target.value);
              }}
              error={formErrors.password}
            />

            <PasswordRequirements requirements={requirements} />
            <TermsCheckbox />
            <Button
              label="Sign Up"
              fontSize="1.2vmax"
              paddingHorizontal="1vmax"
              width="100%"
              onClick={handleSubmit}
            />
          </div>
          <div className={styles.separator}>
            <div className={styles.line} />
            <p>OR</p>
            <div className={styles.line} />
          </div>
          <div className={styles.ssobutton}>
            <div className={styles.continueAsGuest} onClick={() => {}}>
              <Google /> Sign in with Google
            </div>
            <div className={styles.continueAsGuest} onClick={() => {}}>
              <Apple /> Sign in with Apple
            </div>
          </div>
          <div className={styles.signin}>
            Already have an account?{" "}
            <span onClick={() => navigate("/sign-in")}>Sign In</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
