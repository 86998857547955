import styled from "styled-components";
import {
  horizontalScale,
  verticalScale,
  moderateScale,
} from "../../../../../utils/scaling";
import { ReactComponent as LeftArrow } from "./leftarrow.svg";
import { Box, TextField } from "@mui/material";

// Styled component for the left background box
export const LeftBackgroundBox = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 70%;
  background: url("/images/auth-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

// Styled component for the left box's content
export const LeftBackgroundContentsBox = styled(Box)`
  width: 52%;
  height: 87%;
  padding-left: 6%;
  padding-top: 2%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index:20
`;

// Styled component for the right background box
export const RightBackgroundBox = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 49%;
  background-color: white;
  z-index: 10;
  border-top-left-radius: ${moderateScale(32)}px;
  border-bottom-left-radius: ${moderateScale(32)}px;
  padding: 2% 2%;
`;

// Styled component for the form container
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top-left-radius: ${moderateScale(32)}px;
  border-bottom-left-radius: ${moderateScale(32)}px;
  justify-content: space-between;
  background-color: white;
  padding: 0% 4%;
`;

// Styled component for the left arrow icon
export const LeftArrowIcon = styled(LeftArrow)`
  width: ${horizontalScale(50)}px;
  height: ${verticalScale(50)}px;
`;

// Styled component for the stepper container
export const StepperContainer = styled(Box)`
  width: 100%;
`;

// Styled component for the form fields container
export const FormFieldsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 55%;
  padding: 0rem 5%;
`;

// Styled component for the text field
export const TextFieldStyled = styled(TextField)`
  & .MuiInputBase-input {
    font-size: ${verticalScale(22)}px;
  }
  & .MuiInputLabel-root {
    font-size: ${verticalScale(22)}px;
  }
`;

// Styled component for the button container
export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0 5%;
  & .MuiButton-sizeMedium {
    font-size: ${verticalScale(24)}px;
  }
`;
