import React, { useState } from "react";
import Images from "../../../../assets/image";
import SearchSection from "../searchSection/SearchSection";
import ServiceList from "../serviceList/ServiceList";
import styles from "./SectionOne.module.css";

const SectionOne = () => {
  

  return (
    <section className={styles.sectionOne}>
      <section className={styles.leftSection}>
        <div className={styles.introContainer}>
          <h1>
            Home <span>Service </span>
            - &nbsp; <span> Connecting You with Trusted Local Pros</span>
          </h1>
          <SearchSection/>
        </div>
        <ServiceList />
      </section>
      <section className={styles.rightSection}>
        <img src={Images.homeMen} alt="Home Service Professional" />
      </section>
    </section>
  );
};

export default SectionOne;
