import React, { useEffect, useState } from 'react';
import { Button, ServiceSearch, ZipcodeInput, CreateJobModal } from '../../../../components/common';
import TrendingSearch from '../trendingSearch/TrendingSearch';
import styles from './SearchSection.module.css';
import { fetchCategoriesApi, fetchMcqsApi, fetchTrendingTopicsApi } from '../../../../data/api/commonApi';
import CategoryList from '../categoryList/CategoryList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GuestModal from '../createJob/GuestModal';
import { useSelector } from "react-redux";

const SearchSection = () => {
  const [guestModalOpen, setGuestModalOpen] = useState(false);
  const [isGuest, setIsGuest] = useState(true); 
  const [modalOpen, setModalOpen] = useState(false);
  const [trendingTopics, setTrendingTopics] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [showTrends, setShowTrends] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCat, setSelectedCat] = useState({});
  const [mcqs, setMcqs] = useState([]);
  const [zipCode, setZipcode] = useState('');

  // Get the authentication status and guest user info from Redux
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const guestUser = useSelector((state) => state.guest);

  // handle clicks and events
  const handleOpenModal = () => {
    // Zip code validation (assuming 5-digit US zip code)
    const zipCodePattern = /^\d{5}$/;
    if (!zipCodePattern.test(zipCode)) {
      toast.warning("Please enter a valid 5-digit zip code.");
      return;
    }
    if (!selectedCat.name) {
      toast.warning("Please select any category first.");
      return;
    }

    if (!isLoggedIn && !guestUser?.name) {
      setGuestModalOpen(true); 
      return;
    }
    

    setModalOpen(true);
  };

  const handleCloseGuestModal = () => {
    setGuestModalOpen(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const onSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    fetchCats(newValue);
    if (newValue.length > 0) {
      setShowTrends(false);
    }                                                                                
  };

  const handelCatSelection = (cat) =>{
    fetchMcqs(cat.id)
    setSearchValue(cat.name);    
    setSelectedCat(cat);
    setShowTrends(false);
    setShowCategoryList(false);
  };

  const handleTrendingTopicsVisiblity = () => {
    if (categoryList.length === 0) {
      setShowTrends((prevValue) => !prevValue);
    }
  };

  // api calling

  const fetchCats = async (search_term) => {
    try {
      const data = { name: search_term }; 
      const response = await fetchCategoriesApi(data);
      setCategoryList(response.data);
    } catch (error) {
      console.error('Error fetching trending topics:', error);
    }
  };

  const fetchTrendingTopics = async () => {
    try {
      const data = { search: searchValue }; 
      const response = await fetchTrendingTopicsApi(data);
      setTrendingTopics(response.data);
    } catch (error) {
      console.error('Error fetching trending topics:', error);
    }
  };

  const fetchMcqs = async (catId) => {
    try {
      const data = { category_id: catId }; 
      const response = await fetchMcqsApi(data);
      setMcqs(response.data);
    } catch (error) {
      console.error('Error fetching MCQs:', error);
    }
  };

  // use effects 
  useEffect(() => {
    fetchTrendingTopics();
  }, []);

  useEffect(() => {
    if (categoryList.length > 0) {
      setShowCategoryList(true);
      setShowTrends(false);
    }
  }, [categoryList]);

  return (
    <div className={styles.searchSection}>
       
      <ServiceSearch
        onClick={handleTrendingTopicsVisiblity}
        placeholder={"Search for 'AC Service'"}
        onChange={onSearchChange}
        value={searchValue}
      />
      <span>|</span>
      <ZipcodeInput placeholder={"Enter Zip Code"} zipCode={zipCode} setZipcode={setZipcode} />
      <Button
        label={"Find Pros"}
        fontSize={"20px"}
        paddingHorizontal={"12px"}
        paddingVertical={"30px"}
        onClick={handleOpenModal}
      />
      {showTrends && (
        <div className={styles.dropDownMenu}>
          <TrendingSearch trends={trendingTopics} setCategoryList={setCategoryList} />
        </div>
      )}
      {showCategoryList && (
        <div className={styles.dropDownMenu}>
          <CategoryList categoryList={categoryList} handelCatSelection={handelCatSelection} />
        </div>
      )}
     
      {/* Guest Modal */}
      <GuestModal open={guestModalOpen} handleClose={handleCloseGuestModal} setModalOpen={setModalOpen} />
      <CreateJobModal open={modalOpen} handleClose={handleCloseModal} mcqs={mcqs} selectedCat={selectedCat} zipCode={zipCode} />
    </div>
  );
};

export default SearchSection;
