import React from "react";
import ServiceCard from "../serviceCard/ServiceCard";
import Images from "../../../../assets/image";
import styles from "./ServiceList.module.css";

const ServiceList = () => {
  const services = [
    {
      image: Images.frame1,
      title: "Electrician",
      description: "Professional electrical services for homes and businesses.",
      imgClass: "serviceImage1",
    },
    {
      image: Images.framePaint,
      title: "Painting",
      description: "Expert painters for interior and exterior projects.",
      imgClass: "serviceImage2",
    },
    {
      image: Images.frame3,
      title: "Roof Repairing",
      description: "Reliable roof repair services to keep your home safe.",
      imgClass: "serviceImage3",
    },
  ];

  return (
    <div className={styles.servicesContainer}>
      <h1>We provide the following services and many more:</h1>
      <div className={styles.servicesList}>
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            image={service.image}
            title={service.title}
            description={service.description}
            imgClass={service.imgClass}
          />
        ))}
      </div>
    </div>
  );
};

export default ServiceList;
