import { apiRequest } from "./utils.js";

export const fetchTrendingTopicsApi = async (data) => {
  return apiRequest("GET", "/common/fetch-trending-searches", data);
};
export const fetchCategoriesApi = async (data) => {
    return apiRequest("GET", "/common/fetch-category", data);
};
export const fetchMcqsApi = async (data) => {
  return apiRequest("GET", "/common/fetch-mcqs", data);
};
export const generateDescriptionApi = async (data) => {
  return apiRequest("POST", "/common/generate-description-using-ai", data);
};
export const createGuestApi = async (data) => {
  return apiRequest("POST", "/common/guest-login", data);
};
