import { Box } from "@mui/material";
import { TextFieldStyled } from "./VendorSignupStyles";

const PersonalDetails = () => {


  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mb: 0, pb: 0 }}>
        <TextFieldStyled
          fullWidth
          variant="standard"
          margin="dense"
          label="First Name"
          required
          size="small"
        />
        <TextFieldStyled
          fullWidth
          variant="standard"
          margin="dense"
          label="Last Name"
          required
          size="small"
        />
      </Box>

      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Contact Number"
        type="tel"
        required
        size="small"
      />
      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Email ID"
        type="email"
        required
        size="small"
      />
      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Password"
        type="password"
        required
        size="small"
      />
      <TextFieldStyled
        fullWidth
        variant="standard"
        margin="dense"
        label="Zip Code"
        required
        size="small"
      />
    </Box>
  );
};

export default PersonalDetails;
