import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default storage is localStorage for web
import authReducer from './reducers/authReducer';
import profileReducer from './reducers/profileReducer';
import guestReducer from './reducers/guestReducer'; 


const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  guest: guestReducer, 
});


const persistConfig = {
  key: 'root', // key for the root reducer
  storage,     // storage type, default is localStorage
  whitelist: ['auth', 'guest'], // specify which reducers should be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
});


export const persistor = persistStore(store);
