import React from "react";
import styles from "./Button.module.css";

const Button = ({
  label,
  fontSize = "13px",
  paddingHorizontal = "7.5px",
  paddingVertical = "15px",
  backgroundColor = "#353535",
  color = "#FFFFFF",
  border = "1px solid #1A1A1A",
  onClick,
  width,
  margin
}) => {
  const buttonStyle = {
    fontSize,
    padding: `${paddingHorizontal} ${paddingVertical}`,
    backgroundColor,
    color,
    border,
    width,
    margin
  };

  return (
    <button className={styles.button} style={buttonStyle} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
