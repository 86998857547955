import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, RESEND_VERIFICATION_EMAIL_REQUEST, RESEND_VERIFICATION_EMAIL_FAILURE, RESEND_VERIFICATION_EMAIL_SUCCESS, OTP_VERIFICATION_REQUEST, OTP_VERIFICATION_SUCCESS, OTP_VERIFICATION_FAILURE, SET_NEW_PASSWORD_REQUEST, SET_NEW_PASSWORD_SUCCESS, SET_NEW_PASSWORD_FAILURE } from '../actionTypes';
import { createAction } from './utils';

// Action creators for login
export const login = createAction({
  REQUEST: LOGIN_REQUEST,
  SUCCESS: LOGIN_SUCCESS,
  FAILURE: LOGIN_FAILURE,
});

// Action creators for registration
export const register = createAction({
  REQUEST: REGISTER_REQUEST,
  SUCCESS: REGISTER_SUCCESS,
  FAILURE: REGISTER_FAILURE,
});

// Action creators for forgot password
export const forgotPassword = createAction({
  REQUEST: FORGOT_PASSWORD_REQUEST,
  SUCCESS: FORGOT_PASSWORD_SUCCESS,
  FAILURE: FORGOT_PASSWORD_FAILURE,
});


// Action creators for resend verification email
export const resendVerificationEmail = createAction({
  REQUEST: RESEND_VERIFICATION_EMAIL_REQUEST,
  SUCCESS: RESEND_VERIFICATION_EMAIL_SUCCESS,
  FAILURE: RESEND_VERIFICATION_EMAIL_FAILURE,
});

// Action creators for resend verification email
export const otpVerification = createAction({
  REQUEST: OTP_VERIFICATION_REQUEST,
  SUCCESS: OTP_VERIFICATION_SUCCESS,
  FAILURE: OTP_VERIFICATION_FAILURE,
});

// Action creators for set new passowrd
export const setNewPassword = createAction({
  REQUEST: SET_NEW_PASSWORD_REQUEST,
  SUCCESS: SET_NEW_PASSWORD_SUCCESS,
  FAILURE: SET_NEW_PASSWORD_FAILURE,
});

// Action creators for logout
export const logout = createAction({
  REQUEST: LOGOUT_REQUEST,
  SUCCESS: LOGOUT_SUCCESS,
  FAILURE: LOGOUT_FAILURE,
});