import React from "react";
import styles from "./TrendingSearch.module.css";
import Images from "../../../../assets/image";
import { fetchCategoriesApi } from "../../../../data/api/commonApi";


const TrendingSearch = ({trends,setCategoryList}) => {

  const fetchCats = async (search_term) => {
    try {
      const data = { name: search_term }; 
      const response = await fetchCategoriesApi(data);
      setCategoryList(response.data);
      console.log("response for cats::",response);
    } catch (error) {
      console.error('Error fetching trending topics::', error);
    }
  };

  const handelClick = (search_term) => { 
    fetchCats(search_term)
  }
  
  return (
    <div className={styles.dropdownContainer}>
      <h4 className={styles.title}>Trending searches</h4>
      <div className={styles.list}>
        {trends.map((trend, index) => (
          <div key={index} className={styles.item} onClick={()=>handelClick(trend.search_term)}>
            <img src={Images.trendingSearch} alt="Trending Search" className={styles.icon} />
            <p className={styles.name}>{trend.search_term}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingSearch;