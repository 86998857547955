import React from "react";
import ActionBox from "../actionBox/ActionBox";
import Images from "../../../../assets/image";
import styles from "./SectionTwo.module.css";

const SectionTwo = () => {
  return (
    <div className={styles.container}>
      <ActionBox
        heading="Need a quote? Click 'Request a Quote' and provide your details. You can do this without creating an account, making it convenient and quick."
        buttonLabel="Request a Quote"
        image={Images.handHolding}
      />
      <ActionBox
        heading="Are you a service provider? Grow your business by joining our platform. Click 'Join as Service Provider' and complete our multi-step sign-up process to get started."
        buttonLabel="Join Our Pro Network"
        image={Images.peopleWithInternet}
      />
    </div>
  );
};

export default SectionTwo;
