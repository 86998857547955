const styles = {
  container: {
    backgroundColor: "background.default",
    maxHeight: "250px",
    overflowY: "auto",
    padding: "0 !important",
  },
  cardItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    transition: "background-color 0.2s ease-in-out",
    borderRadius: "8px",
    padding: "4px",
    cursor: "pointer", // Change cursor to pointer on hover
    "&:hover": {
      backgroundColor: "#f0f0f0", // Light grey background on hover
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow on hover
    },
  },
  media: {
    borderRadius: "5%",
    width: "15%",
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.2px",
    letterSpacing: "0.04em",
    textAlign: "left",
    width: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  description: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.4px",
    letterSpacing: "0.04em",
    textAlign: "left",
    width: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

export default styles;
