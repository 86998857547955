import React, { useState } from "react";
import { Box, Modal, TextField, Typography, Button, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { setGuestInfo } from "../../../../data/redux/reducers/guestReducer";
import { createGuestApi } from "../../../../data/api/commonApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles";

const GuestModal = ({ open, handleClose, setModalOpen }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const dispatch = useDispatch();

  const validateZipcode = (zipcode) => {
    // Example validation for a 5-digit US Zip Code
    const zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
    return zipCodePattern.test(zipcode);
  };

  const handleSubmit = async () => {
    if (!name || !email || !city || !zipcode) {
      toast.warn("Please fill out all fields");
      return;
    }

    if (!validateZipcode(zipcode)) {
      toast.warn("Please enter a valid zip code");
      return;
    }

    try {
      const data = { email, name, city, zipcode };
      const response = await createGuestApi(data);

      if (response.data) {
        dispatch(setGuestInfo({ name, email, city, zipcode }));
        toast.success("Guest info submitted successfully");
        handleClose();
        setModalOpen(true);
      } else {
        toast.error("Failed to submit guest info");
      }
    } catch (error) {
      toast.error("Error submitting guest info");
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} sx={styles.modalOverlay}>
        <Box
          sx={{
            ...styles.card,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "40vw",
            height: "380px",
          }}
        >
          <IconButton
            aria-label="Close"
            sx={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ ...styles.cardContent, gap: "1rem" }}>
            <Typography
              variant="h5"
              sx={{
                ...styles.titleText,
                textAlign: "center",
                marginBottom: "16px",
              }}
            >
              Continue as Guest
            </Typography>
            <Box sx={{ ...styles.descriptionBox }}>
              <TextField
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <TextField
                  label="City"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextField
                  label="Zip Code"
                  fullWidth
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ ...styles.submitButton, width: "100%" }}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default GuestModal;
