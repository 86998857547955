import React from "react";
import { Footer, Header } from "../../components/layout";
import styles from "./Home.module.css";
import { Review, SectionOne, SectionThree, SectionTwo, ServiceProvide } from "./components";


const Home = () => {
  return (
    <div className={styles.container}>
      <Header />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <ServiceProvide />
      <Review />
      <Footer />
    </div>
  );
};

export default Home;
