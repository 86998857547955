const { default: Review } = require("./review/Review");
const { default: SectionOne } = require("./sections/SectionOne");
const { default: SectionThree } = require("./sections/SectionThree");
const { default: SectionTwo } = require("./sections/SectionTwo");
const { default: ServiceProvide } = require("./serviceProvide/ServiceProvide");
const { default: TrendingSearch } = require("./trendingSearch/TrendingSearch");


module.exports = {
  SectionOne,
  SectionTwo,
  SectionThree,
  ServiceProvide,
  TrendingSearch,
  Review
};
